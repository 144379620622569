import React, { FC } from 'react';
import { Tooltip, Modal, Table } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import {
  ISkuGlobalResponse,
  IWarehouseGlobalResponse,
  TProductCatalogItemResponse,
} from '../../../../../services/product-global/product-global.interface';
import { ColumnsType } from 'antd/lib/table';
type TProductDetailModalProps = {
  product: TProductCatalogItemResponse;
  open: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
};

export const ProductDetailModal: FC<TProductDetailModalProps> = ({
  product,
  open,
  onAccept,
  onCancel,
}) => {
  const columns: ColumnsType<ISkuGlobalResponse> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '80px',
    },
    {
      title: 'Variantes',
      dataIndex: 'skuOptions',
      render: (_, sku) => (
        <>
          {sku.skuOptions?.map((opt, index) => (
            <div key={`${index}-${opt.option?.name}`}>
              {opt.option?.variant?.name}: <b>{opt.option?.name}</b>
            </div>
          ))}
        </>
      ),
    },
  ];

  const columnsWarehouses: ColumnsType<IWarehouseGlobalResponse> = [
    {
      title: 'Almacén',
      dataIndex: 'name',
      render: (_, w) => <>{w.warehouse.name}</>,
    },
    {
      title: 'Contraentrega en',
      dataIndex: 'reference',
      align: 'center',
      render: (_, w) => <>{w.warehouse.reference}</>,
    },
    {
      title: 'Stock en bodega',
      dataIndex: 'stockStore',
      align: 'center',
      render: (_, w) => <>{w.stockStore}</>,
    },
    {
      title: 'Stock Disponible',
      dataIndex: 'stockVirtual',
      align: 'center',
      render: (_, w) => <>{w.stockVirtual}</>,
    },
  ];

  return (
    <Modal
      title={product.name}
      centered
      open={open}
      onOk={onAccept}
      onCancel={onCancel}
      width={1000}
      footer={[]}
    >
      <p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3 style={{ color: 'blue' }}>Información adicional:</h3>
        </div>
        <a target="_blank" rel="noreferrer" href={product.urlReference}>
          {product.urlReference}
        </a>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
          }}
        >
          <h4 style={{ color: 'green' }}>Contacto de proveedor:</h4>
          {product.skus[0].company.setting.phone ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 18, marginLeft: '12px' }}
                href={`
                        https://api.whatsapp.com/send?phone=${product.skus[0].company.setting.phone}&text=${' '
                          .concat('Hola! estoy interesado en este producto ')
                          .concat(product.name)
                          .concat(
                            ' del catalogo de Aliclik, por favor deseo mas información 😃',
                          )}`}
              >
                <Tooltip placement="bottom" title="Contacto de proveedor">
                  <WhatsAppOutlined style={{ color: '#009688' }} />
                </Tooltip>
              </a>
            </>
          ) : null}
        </div>
      </p>
      <h3>Descripción</h3>
      <p>{product.largeDescription}</p>

      <h3>Stock disponible contraentrega</h3>

      <Table
        rowKey="sku"
        columns={columns}
        dataSource={product.skus}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Table
                rowKey="id"
                columns={columnsWarehouses}
                dataSource={record.warehouseSkus.filter(
                  (t) => t.stockStore > 0,
                )}
                pagination={false}
                size="small"
              />
            </>
          ),
          rowExpandable: (record) => !!record.warehouseSkus.length,
        }}
        size="small"
      />

      <p>
        <b>Precio Sugerido Venta Ecom: </b>
        {product.skus[0].currency.symbol}
        {product.retailPriceSuggested.toFixed(2)}
      </p>
      <p>
        <b>Precio Dropshipping (UND): </b>
        {product.skus[0].currency.symbol}
        {product.salePriceDrop.toFixed(2)}
      </p>
    </Modal>
  );
};
