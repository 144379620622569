/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Button, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { IOrderDeliveryCreateRequest } from '../../../../../services/order-delivery-create/order-delivery-create.interface';
import { useOrderDeliveryCreate } from '../../../../../services/order-delivery-create/order-delivery-create.service';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useMotorizedToConveyorList } from '../../../../../services/user-motorized/user-motorized.service';
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface';
import { datadogLogs } from '../../../../../hooks/use-datadog';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useConveyorStore } from '../../../../../services/location/location.service';

const DeliveryMotorizedContainer = () => {
  const { orderDeliveryChecked, setOrderDeliveryUpdated } = useOrderStore();
  const { getMotorizeds, motorizeds, loadingMt } = useMotorizedToConveyorList();
  const {
    saveOrderDelivery,
    response,
    loading: loadingSave,
  } = useOrderDeliveryCreate();
  const [dataRequest, setDataRequest] = useState<IOrderDeliveryCreateRequest[]>(
    [],
  );
  const { closeDrawer } = useDrawer();
  const { authUser } = useAuthStore();
  const { conveyorSelected } = useConveyorStore();

  const handleMotorizedChange = (value: number, option: any) => {
    const data: IOrderDeliveryCreateRequest[] = orderDeliveryChecked.map(
      (order) => {
        return {
          id: order.orderDelivery?.id,
          orderId: order.id,
          userId: option.value,
          method: 'Asignación manual',
          isActive: true,
          isAssigned: true,
          motorized: option.label,
          deliveryDate: order.shipping.scheduleDate,
        };
      },
    );

    saveLogs('ORDERS_ASSIGNED', data);
    setDataRequest(data);
  };

  const handleMotorizedUnassignment = () => {
    const data: IOrderDeliveryCreateRequest[] = orderDeliveryChecked.map(
      (order) => {
        return {
          id: order.orderDelivery?.id,
          orderId: order.id,
          userId: +order.orderDelivery.user.id,
          method: 'Des-asignación manual',
          isActive: true,
          isAssigned: false,
          motorized: null,
          deliveryDate: order.shipping.scheduleDate,
        };
      },
    );

    saveLogs('ORDERS_UNASSIGNED', data);
    saveOrderDelivery(data);
  };

  const saveLogs = (action: string, orders: any) => {
    datadogLogs.logger.info(action, {
      action: action,
      orders: orders,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions,
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
    });
  };

  const handleMotorizedReassigment = () => {
    saveOrderDelivery(dataRequest);
  };

  useEffect(() => {
    if (authUser.user.company.countryCode === 'BOL') {
      getMotorizeds();
    } else {
      getMotorizeds(`${conveyorSelected?.id}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      setOrderDeliveryUpdated(response);
      closeDrawer();
    }
  }, [response]);

  return (
    <>
      <Spin spinning={loadingSave}>
        <Alert
          style={{ marginBottom: '1rem' }}
          message="Asignación de órdenes"
          description={`Se ha seleccionado ${orderDeliveryChecked?.length} órdenes para asignación manual. Puede quitar la asignación o asignar a un nuevo motorizado`}
          type="info"
          showIcon
          action={
            <Button
              onClick={handleMotorizedUnassignment}
              size="small"
              danger
              ghost
            >
              Quitar asignación
            </Button>
          }
        />

        <p>Reasignar a motorizado:</p>
        <Select
          loading={loadingMt}
          style={{ width: '100%', marginBottom: '1rem' }}
          showSearch
          optionFilterProp="label"
          placeholder="Seleccionar motorizado"
          options={motorizeds.map((e: IMotorizedResponse) => ({
            label: e.email,
            value: e.id,
          }))}
          onChange={handleMotorizedChange}
        />

        <Button
          onClick={handleMotorizedReassigment}
          type="primary"
          htmlType="submit"
          size="large"
        >
          Guardar
        </Button>
      </Spin>
    </>
  );
};

export default DeliveryMotorizedContainer;
