import React, { useEffect } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { ITicketSupport } from '../../../../../services/ticket-support-list/ticket-support-list.interface';
import { useTicketStore } from '../../../../../services/ticket-support-create/ticket-support.store';
import {
  TICKET_STATUS_LABEL,
  TICKET_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import { formatDateUTCHour } from '../../../../../utils/date';
import { DeleteFilled, EditFilled, WhatsAppOutlined } from '@ant-design/icons';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import useMeasureY from '../../../../../hooks/use-measurey';
import { useTicketDelete } from '../../../../../services/ticket-support-create/ticket-support-create.service';

interface ITicketsListTableProps {
  tickets: ITicketSupport[];
  loading: boolean;
}

const TicketSupportTable: React.FC<ITicketsListTableProps> = ({
  tickets,
  loading,
}) => {
  const { openDrawer } = useDrawer();
  const { setTkSelected, setTkUpdated } = useTicketStore();
  const { authUser } = useAuthStore();
  const { windowSize } = useMeasureY();
  const { deleteTicket, ticketDeleted } = useTicketDelete();

  const handleSelected = (data: ITicketSupport) => {
    setTkSelected(data);
    openDrawer('TK_CREATE', 'Actualizar ticket');
  };

  const handleDeleted = (data: ITicketSupport) => {
    deleteTicket(data.id);
  };

  useEffect(() => {
    if (ticketDeleted) {
      setTkUpdated(ticketDeleted);
    }
  }, [ticketDeleted]);

  const messageToSupport = (data: ITicketSupport) => {
    return `Hola mi usuario Aliclik es ${authUser.user.email} de la tienda ${authUser.user.company.name},`.concat(
      ` quiero tener información sobre mi ticket de atención nro TK000${data?.id}.`,
    );
  };

  const messageToEcommerce = (data: ITicketSupport) => {
    return `Hola! Te escribo para informarte que tu ticket TK000${data?.id}`.concat(
      ' ha sido actualizado y tiene una respuesta. Que tengas un buen dia! :)',
    );
  };

  const columns: ColumnsType<ITicketSupport> = [
    {
      title: '#Ticket',
      dataIndex: 'id',
      width: '5%',
      align: 'center',
      render: (_, tk) => (
        <>
          <Tag color={'geekblue'}>{`TK00${tk.id}`}</Tag>
          <br></br>
          {formatDateUTCHour(tk.requestedAt)}
          <br></br>
          <Tag color={TICKET_STATUS_LABEL[tk.status].color}>
            {TICKET_STATUS_LABEL[tk.status].label}
          </Tag>
        </>
      ),
    },
    {
      title: 'Tienda',
      dataIndex: 'company',
      width: '8%',
      align: 'center',
      render: (_, tk) => (
        <>
          {tk.company?.name}
          <br></br>({tk.user.email})
        </>
      ),
    },
    {
      title: 'Título',
      dataIndex: 'title',
      width: '10%',
      render: (_, tk) => <>{tk.title}</>,
    },
    {
      title: '#Pedido(s)',
      dataIndex: 'orderNumber',
      width: '8%',
      align: 'center',
      render: (_, tk) => <>{tk.orderNumber}</>,
    },
    {
      title: 'Detalle',
      dataIndex: 'detail',
      width: '12%',
      render: (_, tk) => <>{tk.reason}</>,
    },
    {
      title: 'Respuesta',
      dataIndex: 'comment',
      width: '12%',
    },
    {
      title: 'Seguimiento',
      dataIndex: 'responsiblePhone',
      width: '5%',
      align: 'center',
      render: (_, tk) => (
        <>
          {tk.ticketTopic &&
          tk.ticketTopic.reponsiblePhone &&
          tk.ticketTopic.reponsiblePhone.length > 0 &&
          (authUser.user.role.name === 'SELLER' ||
            authUser.user.role.name === 'ADMIN_STORE') ? (
            <>
              <Tooltip title="Seguimiento">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://api.whatsapp.com/send?phone=${tk.ticketTopic.reponsiblePhone}`.concat(
                    `&text=${messageToSupport(tk)}`,
                  )}
                >
                  <WhatsAppOutlined style={{ color: '#4CAF50' }} />
                </a>
              </Tooltip>
            </>
          ) : null}
          {tk.phone &&
          tk.phone.length > 0 &&
          (authUser.user.role.name === 'ADMIN' ||
            authUser.user.role.name === 'MASTER') ? (
            <>
              <Tooltip title="Seguimiento">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://api.whatsapp.com/send?phone=${tk.phone}`.concat(
                    `&text=${messageToEcommerce(tk)}`,
                  )}
                >
                  <WhatsAppOutlined style={{ color: '#4CAF50' }} />
                </a>
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'edit',
      width: '5%',
      align: 'center',
      render: (_, tk) => (
        <>
          <Tooltip title={'Editar'}>
            <a onClick={() => handleSelected(tk)}>
              <EditFilled />
            </a>
          </Tooltip>
          {authUser.user.role.name === 'ADMIN' &&
          tk.status === TICKET_STATUS_LIST.OPEN ? (
            <>
              <Tooltip title={'Eliminar'}>
                <a
                  onClick={() => handleDeleted(tk)}
                  style={{ marginLeft: '12px' }}
                >
                  <DeleteFilled />
                </a>
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      pagination={false}
      bordered
      dataSource={tickets}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      size="small"
      scroll={{
        x: 1000,
        y: windowSize.height - 200,
      }}
    />
  );
};

export default TicketSupportTable;
