import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { CompanyChangeContainer } from '../../../../../containers';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { useOrderDeliveryList } from '../../../../../services/order-delivery/order-delivery.service';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { OrderResumen, OrdersTable } from '../../components';
import { Select, Badge, Input, DatePicker } from 'antd';
import { ORDER_STATUS_LABEL } from '../../../../../services/order-create/order.params';
import { subDays } from 'date-fns';
import { useLocationLevel1 } from '../../../../../services/location/location.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const OrdersListContainer = () => {
  const { orders, resumen, loading, getOrders, count, page } =
    useOrderDeliveryList();
  const { orderUpdated, orderEvidence } = useOrderStore();
  const { companyGlobal } = useCompanyStore();
  const { authUser } = useAuthStore();
  const [defaultSelected, setDefaultSelected] = useState<string[]>([]);
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const [searchValue, setSearchValue] = useState<string>('');
  const dateFormat = 'YYYY-MM-DD';
  const [selectedDates, setSelectedDates] = useState([
    dayjs(subDays(new Date(), 30)),
    dayjs(new Date()),
  ]);
  const [department, setDepartment] = useState<string>('ALL');
  const [filterBy, setFilterBy] = useState<string>('ALL');
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [filterRecycle, setFilterRecycle] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);

  useEffect(() => {
    if (orderUpdated) {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        page: 1,
        search: searchValue,
        departmentName: department,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        isContacted: filterBy,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  }, [orderUpdated]);

  useEffect(() => {
    if (orderEvidence) {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        page: 1,
        search: searchValue,
        departmentName: department,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        isContacted: filterBy,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  }, [orderEvidence]);

  useEffect(() => {
    setDefaultSelected([]);
    getLocationsGeoLevel1();

    if (
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      getTransporters();
      setHiddenTransp(false);
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }

    if (companyGlobal) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        getOrders({
          companyId: companyGlobal?.id,
          statusList: defaultSelected,
          page: 1,
          search: searchValue,
          departmentName: department,
          startDate: selectedDates[0].format('YYYY-MM-DD'),
          endDate: selectedDates[1].format('YYYY-MM-DD'),
          isContacted: filterBy,
          conveyorId: `${authUser.user.conveyor?.id}`,
          filterRecycle: filterRecycle,
        });
      } else if (authUser.user.role.name === 'SUPER_MASTER') {
        getOrders({
          companyId: companyGlobal?.id,
          statusList: defaultSelected,
          page: 1,
          search: searchValue,
          departmentName: department,
          startDate: selectedDates[0].format('YYYY-MM-DD'),
          endDate: selectedDates[1].format('YYYY-MM-DD'),
          isContacted: filterBy,
          conveyorId: transpSelected,
          filterRecycle: filterRecycle,
        });
      }
    } else if (
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      getOrders({
        companyId: authUser.user.company.id,
        statusList: defaultSelected,
        page: 1,
        search: searchValue,
        departmentName: department,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        isContacted: filterBy,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  }, [companyGlobal]);

  const handleChangeDepartment = (name: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      search: searchValue,
      departmentName: name,
      isContacted: filterBy,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setDepartment(name);
  };

  const handleChange = (status: string[]) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: status,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      departmentName: department,
      search: searchValue,
      isContacted: filterBy,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setDefaultSelected(status);
  };

  const handlePagination = (page: number) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: page,
      departmentName: department,
      search: searchValue,
      isContacted: filterBy,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
  };

  const onSearch = (value: string) => {
    if (value.length > 0) {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        search: value,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        conveyorId: transpSelected,
        page: 1,
        departmentName: department,
        isContacted: filterBy,
        filterRecycle: filterRecycle,
      });
    } else {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        conveyorId: transpSelected,
        page: 1,
        search: value,
        departmentName: department,
        isContacted: filterBy,
        filterRecycle: filterRecycle,
      });
    }

    setSearchValue(value);
  };

  const handleCalendarChange = (dates: any, _: any) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      page: 1,
      search: searchValue,
      departmentName: department,
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      isContacted: filterBy,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setSelectedDates(dates);
  };

  const handleChangeFilterBy = (filter: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      departmentName: department,
      search: searchValue,
      isContacted: filter,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setFilterBy(filter);
  };

  const handleChangeTransporter = (id: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      departmentName: department,
      search: searchValue,
      isContacted: filterBy,
      conveyorId: id,
      filterRecycle: filterRecycle,
    });

    setTranspSelected(id);
  };

  const handleChangeFilterRecycle = (filter: string) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      departmentName: department,
      search: searchValue,
      isContacted: filterBy,
      conveyorId: transpSelected,
      filterRecycle: filter,
    });

    setFilterRecycle(filter);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '.5rem',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '.5rem',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {authUser.user.role?.permissions?.order_company_change ? (
              <CompanyChangeContainer isAll={true} />
            ) : null}

            <Search
              placeholder=""
              onSearch={onSearch}
              enterButton
              style={{ width: 304 }}
            />
            <div>|</div>
            {resumen ? <OrderResumen resumen={resumen} /> : null}

            <h5 style={{ alignContent: 'center' }}>Seleccione</h5>
            <Select
              placeholder="Estados entrega"
              style={{ minWidth: '250px' }}
              onChange={handleChange}
              value={defaultSelected}
              mode="multiple"
              allowClear
              maxTagCount="responsive"
            >
              {Object.keys(ORDER_STATUS_LABEL).map((key) => (
                <Option key={key} value={key}>
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={ORDER_STATUS_LABEL[key].color}
                    text=""
                  />
                  {ORDER_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
          </div>

          <h5>Fechas</h5>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(subDays(new Date(), 30)), dayjs(new Date())]}
            onCalendarChange={handleCalendarChange}
            clearIcon={false}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '.5rem',
            alignItems: 'center',
            marginTop: '10px',
            flexWrap: 'wrap',
          }}
        >
          <h5>Filtro de</h5>

          <Select
            onChange={handleChangeFilterBy}
            optionFilterProp="key"
            value={filterBy}
            style={{ minWidth: '200px' }}
          >
            <Option key="ALL" value="ALL">
              TODOS
            </Option>
            <Option key="iscontacted" value="1">
              Cliente contactado
            </Option>
            <Option key="isNotContacted" value="0">
              Por contactar cliente
            </Option>
          </Select>

          <h5>Departamento</h5>

          <Select
            onChange={handleChangeDepartment}
            showSearch
            optionFilterProp="key"
            loading={loadingGeo1}
            value={department}
            style={{ minWidth: '200px' }}
            filterOption={(input, option) =>
              (option?.key as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="ALL" value="ALL">
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text="">
                TODOS
              </Badge>
            </Option>
            {locationsGeolevel1?.map((key) => (
              <Option key={key.name} value={key.name}>
                {key.name}
              </Option>
            ))}
          </Select>

          <h5>Pedidos</h5>
          <Select
            onChange={handleChangeFilterRecycle}
            optionFilterProp="key"
            value={filterRecycle}
            style={{ minWidth: '100px' }}
          >
            <Option key="ALL" value="ALL">
              TODOS
            </Option>
            <Option key="recycle" value="recycle">
              RECICLADOS
            </Option>
          </Select>

          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <h5>Transportadora</h5>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </div>
      </PageHeader>

      <OrdersTable
        data={orders}
        loading={loading}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  );
};

export default OrdersListContainer;
