import React, { useEffect } from 'react';
import { Spin } from 'antd';
import {
  useGetSetting,
  useSaveSetting,
} from '../../../../services/setting/setting.service';
import { ConfigOrderForm } from '../../forms';
import { ISettingSchema } from '../../../../services/setting/setting.schema';
import { CompanyChangeContainer } from '../../../../containers';
import { PageHeader } from '../../../../components';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../services/company-create/company-create.service';

const ConfigOrderContainer = () => {
  const { authUser } = useAuthStore();
  const { getSetting, setting, loading: loadingGet } = useGetSetting();
  const { saveSetting, loading: loadingSave } = useSaveSetting();
  const { companyGlobal } = useCompanyStore();

  const handleSubmit = (data: ISettingSchema) => {
    saveSetting(data);
  };

  useEffect(() => {
    if (authUser.user.role.name === 'ADMIN_STORE') {
      getSetting(`${authUser.user.company.id}`)
    }
  }, [])

  useEffect(() => {
    if (companyGlobal) {
      getSetting(`${companyGlobal.id}`)
    }
    
  }, [companyGlobal])

  return (
    <>
      <PageHeader>
        {authUser.user.role?.name === 'SUPER_MASTER' ? (
          <CompanyChangeContainer isAll={false} />
        ) : null}
      </PageHeader>

      <Spin spinning={loadingGet || loadingSave}>
        <div className="container" style={{ maxWidth: '720px' }}>
          <ConfigOrderForm initialValues={setting} onSubmit={handleSubmit} />
        </div>
      </Spin>
    </>
  );
};

export default ConfigOrderContainer;
