import React, { FC } from 'react';
import { ModalProvider as ModalProviderHook } from 'react-modal-hook';

// Redux
import { Provider } from 'react-redux';
import store from '../state/store';

// Router
import { BrowserRouter } from 'react-router-dom';

// Custom provider
import { DrawerProvider } from './DrawerContext';
import { ModalProvider } from './ModalContext';
import { ThemeContext } from './ThemeContext';

interface IContextManagerProps {
  children: React.ReactNode;
}

export const ContextManager: FC<IContextManagerProps> = ({ children }) => (
  <BrowserRouter>
    <ThemeContext>
      <Provider store={store}>
        <ModalProviderHook>
          <DrawerProvider>
            <ModalProvider>{children}</ModalProvider>
          </DrawerProvider>
        </ModalProviderHook>
      </Provider>
    </ThemeContext>
  </BrowserRouter>
);
