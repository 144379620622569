import React from 'react';
// import 'antd/dist/antd.min.css'
// import 'antd/dist/antd.dark.min.css'
import './App.css';
import { RouterConfig } from './routes/RouterConfig';
import { ContextManager } from './contexts/ContextManager';
import { Wrapper } from './components';

function App() {
  return (
    <ContextManager>
      <Wrapper>
        <RouterConfig />
      </Wrapper>
    </ContextManager>
  );
}

export default App;
