import { useEffect } from 'react';
import { useAxios, useLocalStorage } from '../../hooks';
import { useCompanyStore } from '../company-create/company-create.service';
import {
  IAuthRequest,
  IAuthResponse,
  IAuthUserResponse,
  ICodeAuthRequest,
  ICodeAuthResponse,
  IEmailSchema,
  IPasswordSchema,
  IResetPasswordResponse,
  IResetPasswordSchema,
  ISessionResponse,
} from './auth.interface';
import { datadogLogs } from '../../hooks/use-datadog';
import { infoDevice } from '../../utils/date';

export const useAuthentication = () => {
  const { setValue } = useAuthStore();
  const { execute, response, loading, error } = useAxios<IAuthResponse>();
  const { setCompanyGlobal } = useCompanyStore();

  const getAuthentication = (auth: IAuthRequest) => {
    const { browser, os } = infoDevice();

    auth.device = `${browser}/${os}`;

    datadogLogs.logger.info('LOGIN', { action: 'LOGIN', user: auth });

    execute({
      method: 'POST',
      url: '/auth/authentication',
      data: auth,
      token: 'undefined',
      isSuccess: true,
    });
  };

  useEffect(() => {
    if (response) {
      setValue(response);
      setCompanyGlobal(null);

      datadogLogs.logger.info('LOGIN_POST', {
        action: 'LOGIN_POST',
        userResponse: response,
      });
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      datadogLogs.logger.info('LOGIN_ERROR:POST', {
        action: 'LOGIN_ERROR:POST',
        userError: error,
      });
    }
  }, [error]);

  return {
    getAuthentication,
    authUser: response,
    loading,
    error,
  };
};

export const useVerify = () => {
  const { setValue, authUser } = useAuthStore();
  const { execute, response, loading, error } = useAxios<IAuthUserResponse>();

  const getVerify = () => {
    execute({
      method: 'GET',
      url: '/auth/verify',
      isSuccess: true,
    });
  };

  useEffect(() => {
    if (response) {
      setValue({
        ...authUser,
        user: response,
      });
    }
  }, [response]);

  return {
    getVerify,
    loading,
    error,
  };
};

export const useAuthStore = () => {
  const { storedValue, setValue, clear } =
    useLocalStorage<IAuthResponse>('ALP_AuthToken');

  const logout = () => {
    clear();
  };

  return {
    logout,
    setValue,
    authUser: storedValue,
    roleUser: storedValue?.user?.role,
  };
};

export const useLogout = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading, error } = useAxios<ISessionResponse>();
  const { clear } = useLocalStorage<IAuthResponse>('ALP_AuthToken');

  const logout = () => {
    execute({
      method: 'POST',
      url: `/auth/logout/${authUser.user.id}`,
      isSuccess: true,
    });
  };

  useEffect(() => {
    if (response) {
      clear();
    }
  }, [response]);

  return {
    logout,
    response,
    loading,
    error,
  };
};

export const useVerifyCodeAuth = () => {
  const { execute, response, loading, error } = useAxios<ICodeAuthResponse>();

  const verifyCodeAuth = (request: ICodeAuthRequest) => {
    execute({
      method: 'POST',
      url: '/auth/verify-code',
      data: request,
      token: 'undefined',
      isSuccess: true,
    });
  };

  return {
    verifyCodeAuth,
    responseCodeAuth: response,
    loadingCodeAuth: loading,
    error,
  };
};

export const useSendCodeAuth = () => {
  const { execute, response, loading, error } = useAxios<ISessionResponse>();

  const sendCodeAuth = (request: IEmailSchema) => {
    execute({
      method: 'POST',
      url: '/auth/send-code',
      data: request,
      token: 'undefined',
      isSuccess: true,
    });
  };

  return {
    sendCodeAuth,
    response,
    loading,
    error,
  };
};

export const useSendCodeAuthResetPassword = () => {
  const { execute, response, loading, error } = useAxios<ISessionResponse>();

  const sendCodeAuth = (request: IPasswordSchema) => {
    execute({
      method: 'POST',
      url: '/auth/send-code/reset-password',
      data: request,
      token: 'undefined',
      isSuccess: true,
    });
  };

  return {
    sendCodeAuth,
    response,
    loading,
    error,
  };
};

export const useResetPassword = () => {
  const { execute, response, loading } = useAxios<IResetPasswordResponse>();

  const resetPassword = (request: IResetPasswordSchema) => {
    execute({
      method: 'PATCH',
      url: `/auth/change-password/${request.userId}`,
      data: {
        password: request.password,
      },
      token: 'undefined',
      isSuccess: true,
    });
  };

  return {
    resetPassword,
    responsePass: response,
    loadingPass: loading,
  };
};
