import { format, addHours, differenceInDays, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
  const hour = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours();
  const min =
    date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${day}/${month}/${year} ${hour}:${min}`;
};

export const formatGeneralDate = (dateStr: string): string => {
  if (!dateStr) {
    return '';
  }

  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day}/${month}/${year}`;
};

/**
 * @deprecated Use formatDateToPeruTime() instead
 */
export const formatDateUTC = (dateStr?: string): string => {
  if (!dateStr) {
    return '';
  }
  const t = new Date(dateStr).toISOString();
  const v = t.split('T');

  return v[1] === '00:00:00.000Z'
    ? format(new Date(addHours(new Date(t), 5)), 'dd/MM/yyyy')
    : formatInTimeZone(dateStr, 'America/Lima', 'dd/MM/yyyy');
};

export const formatDateUTCHour = (dateStr?: string): string => {
  if (!dateStr) {
    return '';
  }
  const t = new Date(dateStr).toISOString();
  const v = t.split('T');

  return v[1] === '00:00:00.000Z'
    ? format(new Date(addHours(new Date(t), 5)), 'dd/MM/yyyy HH:mm:ss')
    : formatInTimeZone(dateStr, 'America/Lima', 'dd/MM/yyyy HH:mm:ss');
};

export const differenceDays = (dateStr?: string): number => {
  const dateScheduled = formatDateUTC(dateStr);
  const dateToday = formatInTimeZone(new Date(), 'America/Lima', 'dd/MM/yyyy');

  // Calculate the difference in days between the two dates
  const diffInDays = differenceInDays(
    parse(dateScheduled, 'dd/MM/yyyy', new Date()),
    parse(dateToday, 'dd/MM/yyyy', new Date()),
  );

  return diffInDays;
};

export const validateUrl = (url: string): boolean => {
  if (url.includes('Payment_Images')) {
    return true;
  }

  return false;
};

export const validateUrlDelivery = (url: string): boolean => {
  if (url.includes('OrderDelivery_Images')) {
    return true;
  }

  return false;
};

export const infoDevice = () => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobi|Android/i.test(userAgent);
  let os = 'Unknown OS';
  let browser = 'Unknown Browser';

  // Detectar Sistema Operativo
  if (userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  if (userAgent.indexOf('Linux') !== -1) os = 'Linux';
  if (userAgent.indexOf('Android') !== -1) os = 'Android';
  if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1)
    os = 'iOS';

  // Detectar navegador
  if (userAgent.indexOf('Chrome') !== -1) browser = 'Chrome';
  if (userAgent.indexOf('Firefox') !== -1) browser = 'Firefox';
  if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1)
    browser = 'Safari';
  if (userAgent.indexOf('Edge') !== -1) browser = 'Edge';
  if (userAgent.indexOf('Trident') !== -1) browser = 'Internet Explorer';

  return {
    os: os,
    browser: browser,
    isMobile: isMobile,
  };
};
