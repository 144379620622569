import React, { FC } from 'react';
import { IOrderResumen } from '../../../../../services/order-list/order.interface';
import './OrderResumen.css';
import { Space } from 'antd';

interface IOrderResumenProps {
  resumen: IOrderResumen;
}

const OrderResumen: FC<IOrderResumenProps> = ({ resumen }) => {
  return (
    <div
      style={{
        backgroundColor: '#F28B82',
        padding: '5px 5px 10px 5px', // Reducido el padding en todos los lados
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        boxSizing: 'border-box',
        margin: '10px 0', // Añadido margen arriba y abajo
        textAlign: 'center', // Centrar el contenido
      }}
    >
      <Space wrap>
        <div style={{ fontSize: '18px', fontWeight: 600 }}>
          <span className="OrderResumen__label">Pedidos: </span>
          <b style={{ color: 'white' }}>{resumen?.totalOrder || 0}</b>
        </div>
        <div style={{ fontSize: '18px', fontWeight: 600 }}>
          <span className="OrderResumen__label">Entregados: </span>
          <b style={{ color: 'white' }}>{resumen?.totalOrderDelivered || 0}</b>
        </div>
        <div style={{ fontSize: '18px', fontWeight: 600 }}>
          <span className="OrderResumen__label">Total: </span>
          <b style={{ color: 'white' }}>
            {resumen?.currency?.symbol} {resumen?.amount?.toFixed(2) || 0}
          </b>
        </div>
        <div style={{ fontSize: '18px', fontWeight: 600 }}>
          <span className="OrderResumen__label">Recaudo: </span>
          <b style={{ color: 'white' }}>
            {resumen?.currency?.symbol} {resumen?.totalPayment?.toFixed(2) || 0}
          </b>
        </div>
      </Space>
    </div>
  );
};

export default OrderResumen;
