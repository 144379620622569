import React, { FC, useEffect } from 'react'
import { Button, Table, TableColumnsType } from 'antd'
import { useCoverageUpdate } from '../../../../../../services/coverage-update/coverage-update.service'
import { useCoverageStore } from '../../../../../../services/coverage-create/coverage-create.store'
import { ICoverage, ICoverageConveyor } from '../../../../../../services/location/location.interface'
import { useDrawer } from '../../../../../../contexts/DrawerContext'

interface ICoverageByUserTableProps {
  record: ICoverageConveyor
}

const CoverageByUserTable: FC<ICoverageByUserTableProps> = ({ record }) => {
  const { updateCoverage, coverageUpdated } = useCoverageUpdate()
  const { setCoverageUpdated, setCoverageUbigeoUpdated } = useCoverageStore()
  const { openDrawer } = useDrawer()

  const handleCoverageUpdate = (id: number) => {
    updateCoverage(id)
  }

  const handleCoverageUbigeoUpdate = (coverageUbigeo: ICoverage) => {
    setCoverageUbigeoUpdated(coverageUbigeo)
    openDrawer('COVERAGE_EDIT', 'Actualizar cobertura')
  }

  useEffect(() => {
    if (coverageUpdated) {
      setCoverageUpdated(coverageUpdated)
    }
  }, [coverageUpdated])

  const columnsLevel3: TableColumnsType<ICoverage> = [
    {
      title: 'Almacen',
      dataIndex: 'warehouse',
      align: 'center',
      render: (_, coverage) => <>{coverage.warehouse.name}
      </>,
    },
    {
      title: 'Tiempo llegada',
      dataIndex: 'addDays',
      align: 'center',
      render: (_, coverage) => <>
        {coverage.addDays}
      </>,
    },
    {
      title: 'Costo entrega',
      dataIndex: 'deliveryCost',
      align: 'center',
      render: (_, coverage) => <>
        {coverage.deliveryCost}
      </>,
    },
    {
      title: 'Costo devolución',
      dataIndex: 'returnCost',
      align: 'center',
      render: (_, coverage) => <>
        {coverage.returnCost}
      </>,
    },
    {
      title: 'Costo entrega premium',
      dataIndex: 'deliveryCostPremium',
      align: 'center',
      render: (_, coverage) => <>
        {coverage.deliveryCostPremium}
      </>,
    },
    {
      title: 'Costo devolución premium',
      dataIndex: 'returnCostPremium',
      align: 'center',
      render: (_, coverage) => <>
        {coverage.returnCostPremium}
      </>,
    },
    {
      title: '', dataIndex: 'coverage', width: 120, align: 'center',
      render: (_, coverageUbigeo) => <Button type="link" onClick={() => handleCoverageUbigeoUpdate(coverageUbigeo)}>
        Editar
      </Button>,
    },
    {
      title: '', dataIndex: 'coverage', width: 120, align: 'center',
      render: (_, { id }) => <Button type="link" danger onClick={() => handleCoverageUpdate(id)}>
        Eliminar
      </Button>,
    },
  ]

  return <>
    <Table
      rowKey="id"
      columns={columnsLevel3}
      dataSource={record.transporters}
      pagination={false}
      size="small"
    />
  </>
}

export default CoverageByUserTable
