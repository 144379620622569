import { useState } from 'react';
import axios from 'axios';
import { ITransporterResponse } from './transporter.interface';
import { ITransporterCreateSchema } from '../transporter-create/transporter-create.schema';

export const useTransporterStore = () => {
  const [transporterSelected, setTransporterSelected] =
    useState<ITransporterResponse | null>(null);
  const [transporterUpdated, setTransporterUpdated] =
    useState<ITransporterResponse | null>(null);
  const [transporterDeleted, setTransporterDeleted] =
    useState<ITransporterResponse | null>(null);
  const [transporterCreated, setTransporterCreated] =
    useState<ITransporterResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [transporters, setTransporters] = useState<ITransporterResponse[]>([]);

  // Función para obtener la lista de transportistas
  const fetchTransporters = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/transport');

      setTransporters(response.data.transports);
    } catch (error) {
      console.error('Error fetching transporters:', error);
    } finally {
      setLoading(false);
    }
  };

  // Función para crear un transportista
  const createTransporter = async (data: ITransporterCreateSchema) => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-console
      console.log('Data being sent to create:', data); // Log para verificar los datos que se envían
      const response = await axios.post('/transport/create', data);

      setTransporterCreated(response.data);
      fetchTransporters(); // Actualizar lista después de crear
    } catch (error) {
      console.error('Error creating transporter:', error);
    } finally {
      setLoading(false);
    }
  };

  // Función para actualizar un transportista
  const updateTransporter = async (
    id: number,
    data: Partial<ITransporterCreateSchema>,
  ) => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-console
      console.log(`Updating transporter with ID ${id} and data:`, data); // Log para verificar los datos que se envían
      const response = await axios.patch(`/transport/update/${id}`, data);

      setTransporterUpdated(response.data);
      fetchTransporters(); // Actualizar lista después de actualizar
    } catch (error) {
      console.error('Error updating transporter:', error);
    } finally {
      setLoading(false);
    }
  };

  // Función para eliminar un transportista
  const deleteTransporter = async (id: number) => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-console
      console.log(`Deleting transporter with ID ${id}`); // Log para verificar el ID que se elimina
      await axios.delete(`/transport/delete/${id}`);
      setTransporters(
        transporters.filter((transporter) => transporter.id !== id),
      );
      setTransporterDeleted({ id } as ITransporterResponse);
    } catch (error) {
      console.error('Error deleting transporter:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    transporterSelected,
    transporterUpdated,
    transporterDeleted,
    transporterCreated,
    transporters,
    loading,
    setTransporterSelected,
    setTransporterUpdated,
    createTransporter,
    updateTransporter,
    deleteTransporter,
    fetchTransporters,
  };
};
