import React, { FC, useEffect } from 'react';
import { Modal, Rate, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_STATUS_LABEL,
  ORDER_STATUS_LIST,
} from '../../../../../../services/order-create/order.params';
import { OrderActionIcons } from '../..';
import { useDrawer } from '../../../../../../contexts/DrawerContext';
import { CALL_STATUS_LABEL } from '../../../../../../services/pre-order/pre-order.params';
import {
  IOrderDeliveryResponse,
  IOrderGroupLocationResponse,
  IOrderItemResponse,
} from '../../../../../../services/order-list/order.interface';
import { useOrderStore } from '../../../../../../services/order-list/order.store';
import { formatDateUTC, formatDateUTCHour } from '../../../../../../utils/date';
import {
  CalendarOutlined,
  CheckCircleFilled,
  CustomerServiceFilled,
  DollarOutlined,
  DownloadOutlined,
  FolderAddFilled,
  InteractionOutlined,
  QrcodeOutlined,
  SendOutlined,
  ShopOutlined,
  UserSwitchOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { useModal } from '../../../../../../contexts/ModalContext';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import {
  Document,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {
  useOrderDisableScan,
  useOrderRescheduled,
} from '../../../../../../services/order-level/order-level.service';
import { MotocycleIcon } from '../../../../../../components/Icons';

interface IOrdersTableLevel3Props {
  record: IOrderGroupLocationResponse;
}

const confirm = Modal.confirm;

const OrdersTableLevel3: FC<IOrdersTableLevel3Props> = ({ record }) => {
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const { setOrderSelected, setOrderUpdated } = useOrderStore();
  const { authUser } = useAuthStore();
  const { updateValidateOrderRescheduled, orderUpdated } =
    useOrderRescheduled();
  const { setDisableScanOrder, orderScanUpdated } = useOrderDisableScan();

  const handleSelectedOrder = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openDrawer('ORDER_DELIVERY_EDIT', 'Editar orden');
  };

  const handleOrderPayments = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openModal('ORDER_PAYMENTS', 'Pagos');
  };

  const handleOrderDeliveries = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openModal('ORDER_DELIVERIES', 'Historial de entregas');
  };

  const handleUpdateEvidence = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openDrawer('ORDER_EVIDENCE_EDIT', `Editar evidenia ${order.orderNumber}`);
  };

  /*
  const handleOrderChat = (order: IOrderItemResponse) => {
    setOrderSelected(order)
    openDrawer('ORDER_CHAT', `Chat #${order.orderNumber}`)
  }*/

  /*
  const handleCustomerPhoneInfo = (order: IOrderItemResponse) => {
    setOrderSelected(order)
    openDrawer('CUSTOMER_PHONE_INFO',
      `${order.customerHistory.name} ${order.customerHistory.lastName} - ${order.customerHistory.phone}`)
  }*/

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    row: {
      flexGrow: 1,
      fontSize: 5,
      flexDirection: 'row',
    },
    col1: {
      width: '40%',
    },
    text: {
      width: '60%',
    },
    text1: {
      width: '100%',
    },
    textRigth: {
      textAlign: 'right',
    },
    textRigth1: {
      textAlign: 'right',
      fontSize: 8,
    },
    textRigth2: {
      textAlign: 'right',
      fontSize: 5,
    },
    textMedia: {
      width: '50%',
    },
    section1: {
      borderColor: '#000',
      borderWidth: '0.5px',
      borderRadius: '5px',
      borderStyle: 'solid',
      marginTop: 4,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section: {
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      paddingTop: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section2: {
      marginTop: 2,
      marginLeft: 8,
      marginRight: 8,
      padding: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    section3: {
      marginTop: 0,
      marginLeft: 8,
      marginRight: 8,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: 8,
      fontWeight: 'light',
    },
    textCenter: {
      fontSize: 7,
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
    textCenter1: {
      fontSize: 7,
      width: '100%',
      textAlign: 'center',
      fontWeight: 'ultrabold',
    },
  });

  const DocPdf = (order: IOrderItemResponse) => {
    return (
      <Document>
        {order ? (
          <>
            (
            <Page size={[162, 162]} key={order.orderNumber}>
              <View style={styles.section}>
                <View style={styles.row}>
                  <Text style={styles.textCenter1}>
                    {order.shipping.provinceName}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>{order.orderNumber}</Text>
                  <View style={styles.col1}>
                    <Text style={styles.textRigth2}>
                      {order.transporter?.name}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <View style={{ width: '70%' }}>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        Almacén: {order.warehouseName}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.textRigth}>
                        #Guía: {order.dispatchGuide?.guideNumber || '-'}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text}>Producto(s):</Text>
                    </View>
                    <View style={styles.row}>
                      <Text style={styles.text1}>
                        {order.orderDetails?.map((o) => {
                          return `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions
                            ?.map((op) => op.option?.name)
                            .join('')}`
                            .concat(` - ${o.sku.company.setting.companyPrefix}`)
                            .concat('\n');
                        })}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Image
                      source={'https://aliclikapp-api.herokuapp.com/order-public/generate-qr'.concat(
                        `/${order.orderNumber}`,
                      )}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.section1}>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    Monto a cobrar: {order.currency.symbol}
                    {order.total}
                  </Text>
                  <Text style={styles.textRigth}>
                    Distrito: {order.shipping.districtName || '-'}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.text}>
                    NOTA: {order.note}
                    {'\n'}
                    CLIENTE: {order.customerHistory.name}{' '}
                    {order.customerHistory.lastName}
                  </Text>
                </View>
              </View>
              <View style={styles.section2}>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>
                    Fecha entrega: {formatDateUTC(order.shipping.scheduleDate)}
                  </Text>
                  <Text style={styles.textMedia}>
                    Fecha despacho: {formatDateUTC(order.shipping.dispatchDate)}
                  </Text>
                </View>
              </View>
              <View style={styles.section3}>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>Reprogramado 1:</Text>
                  <Text style={styles.textMedia}>No contesta 1:</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.textMedia}>Reprogramado 2:</Text>
                  <Text style={styles.textMedia}>No contesta 2:</Text>
                </View>
              </View>
            </Page>
            )
          </>
        ) : null}
      </Document>
    );
  };

  const placeHolderRate = (order: IOrderItemResponse) => {
    const totalDelivered = order.customerHistory?.totalOrdersDelivered || 0;
    const totalNotDelivered =
      order.customerHistory?.totalOrdersNotDelivered || 0;

    return `#Entregados: ${totalDelivered} / #NoEntregados ${totalNotDelivered}`;
  };

  const calculateRate = (order: IOrderItemResponse) => {
    const totalDelivered = order.customerHistory?.totalOrdersDelivered || 0;
    const totalNotDelivered =
      order.customerHistory?.totalOrdersNotDelivered || 0;

    const total = totalDelivered + totalNotDelivered;

    let value = 0;

    if (total > 0) {
      const percentage = (totalDelivered / total) * 100;

      if (percentage > 85) value = 5;
      else if (percentage < 85 && percentage > 70) value = 4;
      else if (percentage < 70 && percentage > 60) value = 3;
      else if (percentage < 60 && percentage > 40) value = 2;
      else value = 0;
    }

    return value;
  };

  const disableScanOrder = (orderId: number, orderNumber: string) => {
    confirm({
      title: 'Liberar QR',
      content: `¿Desea liberar el escaneo del rótulo para entregar el pedido ${orderNumber}?`,
      okText: 'SI',
      cancelText: 'NO',
      onOk() {
        setDisableScanOrder(orderId);
      },
    });
  };

  const validateRescheduled = (
    orderNumber: string,
    order: IOrderDeliveryResponse,
  ) => {
    const dateRescheduled = formatDateUTC(order.dateRescheduled);

    confirm({
      title: 'Validar reprogramación',
      content: `¿Desea validar la reprogramación del pedido
      ${orderNumber} para la fecha ${dateRescheduled}?`,
      okText: 'SI',
      cancelText: 'NO',
      onOk() {
        updateValidateOrderRescheduled(order.id);
      },
    });
  };

  useEffect(() => {
    if (orderUpdated) {
      setOrderUpdated(orderUpdated);
    }
  }, [orderUpdated]);

  useEffect(() => {
    if (orderScanUpdated) {
      setOrderUpdated(orderScanUpdated);
    }
  }, [orderScanUpdated]);

  const handleOrderRecycle = (order: IOrderItemResponse) => {
    setOrderSelected(order);
    openDrawer('ORDER_RECYCLE');
  };

  const columnsLevel3: TableColumnsType<IOrderItemResponse> = [
    {
      title: 'Order',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          <a onClick={() => handleSelectedOrder(order)}>{order.orderNumber}</a>
          <h6>
            {order.companyHistory ? `\n${order.companyHistory.name}` : ''}
          </h6>
          {order.sourceOrderId ? (
            <>
              <Tooltip placement="bottom" title="store central">
                <ShopOutlined style={{ color: '#1e07f3' }} />
              </Tooltip>
            </>
          ) : null}
          {order.messageStoreCentral ? (
            <>
              <Tooltip placement="bottom" title={order.messageStoreCentral}>
                <ShopOutlined style={{ color: '#C62828' }} />
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'comunication',
      width: 100,
      render: (_, order) => <OrderActionIcons order={order} />,
    },
    {
      title: 'Delivery',
      dataIndex: 'delivery',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          <a onClick={() => handleOrderPayments(order)}>
            <DollarOutlined
              style={{
                fontSize: '1.1rem',
                margin: '0 .25rem',
                cursor: 'pointer',
              }}
            />
          </a>
          <a onClick={() => handleOrderDeliveries(order)}>
            <SendOutlined
              style={{
                fontSize: '1.1rem',
                margin: '0 .25rem',
                cursor: 'pointer',
              }}
            />
          </a>
          {authUser.user.role.name === 'ADMIN' && order.isAssigned ? (
            <>
              <a onClick={() => handleUpdateEvidence(order)}>
                <Tooltip placement="bottom" title="Actualizar evidencias">
                  <FolderAddFilled
                    style={{
                      fontSize: '1.1rem',
                      margin: '0 .25rem',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </a>
            </>
          ) : null}
          {/*<a onClick={() => handleOrderChat(order)}>
          <WechatOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
        </a>*/}
          {/*<a onClick={() => handleDownloadPdf(order)}>
          <DownloadOutlined style={{ fontSize: '1.1rem', margin: '0 .25rem', cursor: 'pointer' }} />
        </a>*/}
          <>
            <PDFDownloadLink
              document={DocPdf(order)}
              fileName={`${order.orderNumber}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <Tooltip placement="bottom" title="rótulo">
                  <DownloadOutlined
                    style={{
                      fontSize: '1.1rem',
                      margin: '0 .25rem',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </PDFDownloadLink>
          </>

          {authUser.user.company.isOrderRecycle === true &&
          order.quantityRecycled === 0 &&
          order.callStatus === 'CONFIRMED' &&
          order.isOrderAgency === false ? (
            <>
              {order.status === 'DELIVERED' ||
              order.status === 'PENDING_DELIVERY' ||
              order.status === 'REFUSED' ||
              order.status === 'RESCHEDULED' ||
              order.status === 'OUT_OF_STOCK' ||
              order.status === 'NOT_DISPATCH' ? null : (
                <>
                  {order.dispatchStatus === 'AVAILABLE' ? (
                    <>
                      <a onClick={() => handleOrderRecycle(order)}>
                        <Tooltip placement="bottom" title="Reciclar pedido">
                          <InteractionOutlined
                            style={{
                              fontSize: '1.1rem',
                              margin: '0 .25rem',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                      </a>
                    </>
                  ) : null}
                </>
              )}
            </>
          ) : null}
          {order.quantityRecycled > 0 ? (
            <>
              <a onClick={() => handleOrderRecycle(order)}>
                <Tooltip placement="bottom" title="ver historial de cliente">
                  <UserSwitchOutlined
                    style={{
                      fontSize: '1.5rem',
                      margin: '0 .25rem',
                      cursor: 'pointer',
                      color: 'red',
                    }}
                  />
                </Tooltip>
              </a>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Trazabilidad',
      width: 160,
      render: (
        _,
        {
          callStatus,
          isOrderAgency,
          warehouseName,
          status,
          orderNumber,
          transporter,
          id,
          rescheduledNumber,
          dispatchGuide,
          dispatchStatus,
          orderDelivery,
          warehousePhone,
          disableScan,
        },
      ) => (
        <>
          <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
            {CALL_STATUS_LABEL[callStatus]?.label}
          </Tag>
          <Tag color={isOrderAgency ? '#FFB74D' : '#00796B'}>
            {isOrderAgency ? 'Agencia' : 'Contraentrega'}
          </Tag>
          <Tag color="#673AB7" hidden={warehouseName ? false : true}>
            {warehouseName}
          </Tag>
          {warehousePhone &&
          warehousePhone.length > 0 &&
          authUser.user.role.name !== 'STORE' ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${warehousePhone}`}
              >
                <Tooltip placement="bottom" title="WhatsApp almacén">
                  <WhatsAppOutlined className="OrderActionIcons-green" />
                </Tooltip>
              </a>
            </>
          ) : null}
          <Tag color="#0097A7">{transporter?.name.toUpperCase()}</Tag>
          {dispatchGuide ? (
            <>
              <Tag color="#FF4081">{`#Guía: ${dispatchGuide.guideNumber}`}</Tag>
            </>
          ) : null}
          <Tag color="#757575">
            {ORDER_DISPATCH_STATUS_LABEL[dispatchStatus].label}
          </Tag>
          {status !== ORDER_STATUS_LIST.DELIVERED ? (
            <>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://api.whatsapp.com/send?phone=51953378661'}
              >
                <Tooltip placement="bottom" title="Soporte Entregas">
                  <CustomerServiceFilled className="OrderActionIcons-blue" />
                </Tooltip>
              </a>
            </>
          ) : null}
          <Tag color={ORDER_STATUS_LABEL[status]?.color}>
            {ORDER_STATUS_LABEL[status]?.label}
          </Tag>
          {status === ORDER_STATUS_LIST.RESCHEDULED &&
          authUser.user.role.name === 'ADMIN' &&
          orderDelivery &&
          orderDelivery.isValidatedRescheduled === false &&
          orderDelivery.dateRescheduled ? (
            <>
              <a
                onClick={() => validateRescheduled(orderNumber, orderDelivery)}
              >
                <Tooltip placement="bottom" title="Validar reprogramación">
                  <Tag color={ORDER_STATUS_LABEL[status]?.color}>
                    {formatDateUTC(orderDelivery.dateRescheduled)}
                  </Tag>
                  <CalendarOutlined style={{ color: '#FB8C00' }} />
                </Tooltip>
              </a>
            </>
          ) : null}
          {status !== ORDER_STATUS_LIST.DELIVERED &&
          authUser.user.role.name === 'MASTER' ? (
            <>
              {disableScan === false ? (
                <>
                  <a onClick={() => disableScanOrder(id, orderNumber)}>
                    <Tooltip placement="bottom" title="Liberar QR">
                      <QrcodeOutlined style={{ color: '#9E9E9E' }} />
                    </Tooltip>
                  </a>
                </>
              ) : (
                <>
                  <Tooltip placement="bottom" title="QR Liberado">
                    <QrcodeOutlined style={{ color: '#E91E63' }} />
                  </Tooltip>
                </>
              )}
            </>
          ) : null}
          {orderDelivery &&
          orderDelivery.isValidatedRescheduled === true &&
          authUser.user.role.name === 'ADMIN' &&
          orderDelivery.dateRescheduled ? (
            <>
              <Tag color={ORDER_STATUS_LABEL[status]?.color}>
                {formatDateUTC(orderDelivery.dateRescheduled)}
              </Tag>
              <CalendarOutlined style={{ color: '#FB8C00' }} />
              <CheckCircleFilled style={{ color: '#FB8C00' }} />
            </>
          ) : null}
          <Tag color="#FB8C00" hidden={rescheduledNumber === 0}>
            {`#Visita: ${rescheduledNumber + 1}`}
          </Tag>
        </>
      ),
    },
    {
      title: 'Importes',
      dataIndex: 'total',
      width: 150,
      align: 'center',
      render: (
        _,
        {
          currency,
          total,
          totalPayment,
          shippingCost,
          returnCost,
          totalDrop,
          status,
          additionalDeliveryCost,
        },
      ) => (
        <>
          <b>
            &#128181; {currency?.symbol} {total.toFixed(2)}
          </b>
          <br />
          &#128176; {currency?.symbol}{' '}
          {totalPayment > 0 ? totalPayment.toFixed(2) : '-'}
          {status === 'DELIVERED' || status === 'PENDING_DELIVERY' ? (
            <>
              <br />
              &#128666; {currency?.symbol}{' '}
              {shippingCost
                ? (shippingCost + (additionalDeliveryCost || 0)).toFixed(2)
                : '- '}
            </>
          ) : null}
          {status === 'CANCEL' ||
          status === 'NOT_RESPOND' ||
          status === 'TRAVEL' ||
          status === 'OUT_OF_COVER' ||
          status === 'REFUSED' ||
          status === 'ANNULLED' ? (
            <>
              <br />
              &#128666; {currency?.symbol}{' '}
              {returnCost
                ? (returnCost + (additionalDeliveryCost || 0)).toFixed(2)
                : '- '}
            </>
          ) : null}
          {status === 'DELIVERED' || status === 'PENDING_DELIVERY' ? (
            <>
              <br />
              &#128230; {currency?.symbol}{' '}
              {totalDrop > 0 ? totalDrop.toFixed(2) : '-'}
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Pagos',
      dataIndex: 'totalPayment',
      width: 100,
      align: 'center',
      render: (_, { totalCash, totalPOS, totalTransfer }) => (
        <>
          💲 {totalCash > 0 ? `${totalCash.toFixed(2)} ` : '- '}
          <br />
          &#128177; {totalTransfer > 0 ? `${totalTransfer.toFixed(2)} ` : '- '}
          <br />
          &#128179; {totalPOS > 0 ? `${totalPOS.toFixed(2)} ` : '- '}
        </>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'orderDetails',
      width: 240,
      render: (_, { orderDetails }) => (
        <>
          {orderDetails?.map((order) => (
            <div key={order.id}>
              {order.quantity} {order.sku.product?.name}
              {order.sku.skuOptions
                ?.filter((e) => e.option?.name !== '_DEFAULT')
                .map((op) => (
                  <span key={op.id}>
                    <span> / </span>
                    {op.option?.name}
                  </span>
                ))}
              <span> ({order.sku.company.setting.companyPrefix})</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Nota',
      dataIndex: 'note',
      width: 140,
    },
    {
      title: 'Cliente',
      dataIndex: 'customerHistory',
      width: 120,
      align: 'center',
      render: (_, order) => (
        <>
          <Tooltip title={placeHolderRate(order)}>
            {order.customerHistory?.name} {order.customerHistory?.lastName}
            <br />
            {order.customerHistory?.phone}
            <br />
            <Rate disabled value={calculateRate(order)} />
          </Tooltip>
          <br />
          <Tag color={`${order.isContacted ? '#2196F3' : '#9b9b9b'}`}>
            {order.isContacted
              ? `Cliente contactado ${order.contactedAt ? formatDateUTCHour(order.contactedAt) : ''}`
              : ''}
          </Tag>
        </>
      ),
    },
    {
      title: 'Dirección',
      dataIndex: 'address1',
      width: 180,
      align: 'center',
      render: (_, { shipping }) => (
        <>
          {shipping?.districtName}
          <br></br>
          {shipping?.address1}
          <br></br>
          {shipping?.address2 ? shipping.address2 : '-'}
        </>
      ),
    },
    {
      title: 'Entrega',
      dataIndex: 'scheduleDate',
      align: 'center',
      width: 120,
      render: (_, { shipping, orderDelivery, isAssigned }) => (
        <>
          FD:{' '}
          {shipping?.dispatchDate ? formatDateUTC(shipping?.dispatchDate) : '-'}
          <br></br>
          FE: {formatDateUTC(shipping?.scheduleDate)}
          {(authUser.user.role.name === 'ADMIN' ||
            authUser.user.role.name === 'MASTER') &&
          isAssigned === true &&
          orderDelivery &&
          orderDelivery.user.phone &&
          orderDelivery.user.phone.length > 0 ? (
            <>
              <br></br>
              {isAssigned === true ? orderDelivery?.user?.email : ''}
              <br></br>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${orderDelivery.user.phone}`}
              >
                <MotocycleIcon />
              </a>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendor',
      width: 100,
      align: 'center',
      render: (_, { user }) => <>{user.fullname}</>,
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columnsLevel3}
        dataSource={record.orders}
        pagination={false}
        scroll={{
          x: 2000,
        }}
      />
    </>
  );
};

export default OrdersTableLevel3;
