import React, { useEffect, useState } from 'react';
import { Badge, Button, Empty, Flex, Select, Spin } from 'antd';
import { PageHeader } from '../../../../../components';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useOrderMotorizedList } from '../../../../../services/order-motorized/order-motorized.service';
import { IOrderFilter } from '../../../../../services/order-list/order.interface';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { DeliveryResumen, DeliveryTable } from '../../components';
import { DeliveryFilterForm } from '../../forms';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import {
  getProvinceList,
  useConveyorStore,
} from '../../../../../services/location/location.service';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { useOrderMapList } from '../../../../../services/order-map/order-map.service';
import { IOrderDeliveryItemResponse } from '../../../../../services/order-motorized/order-motorized.interface';
import { useMotorizedToConveyorList } from '../../../../../services/user-motorized/user-motorized.service';
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface';
import { useOrderDeliveryCreate } from '../../../../../services/order-delivery-create/order-delivery-create.service';
import { IOrderDeliveryCreateRequest } from '../../../../../services/order-delivery-create';
import { datadogLogs } from '../../../../../hooks/use-datadog';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import { MotocycleIcon } from '../../../../../components/Icons';

const { Option } = Select;

const OrdersListContainer = () => {
  const { orders, loading, getOrders } = useOrderMotorizedList();
  const { ordersMap, getOrdersMap, loadingMap } = useOrderMapList();
  const { getMotorizeds, motorizeds, loadingMt } = useMotorizedToConveyorList();
  const {
    saveOrderDelivery,
    response,
    loading: loadingSave,
  } = useOrderDeliveryCreate();
  const {
    orderUpdated,
    orderDeliveryChecked,
    setOrderDeliveryChecked,
    orderDeliveryUpdated,
    setOrderDeliveryUpdated,
  } = useOrderStore();
  const { authUser } = useAuthStore();
  const [filterValue, setFilterValue] = useState<IOrderFilter>();
  const { openDrawer } = useDrawer();
  const provinceList = getProvinceList();
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL');
  const [defaultSend, setDefaultSend] = useState<string>('FALSE');
  const [infoWindow, setInfoWindow] = useState<boolean>(false);
  const [ubigeo, setUbigeo] = useState<google.maps.LatLng>();
  const [orderMap, setOrderMap] = useState<IOrderDeliveryItemResponse>();
  const [motorized, setMotorized] = useState<IMotorizedResponse>();
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const { setConveyorSelected } = useConveyorStore();

  /*const pathMarker = 'M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.'
    .concat('017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.')
    .concat('456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.')
    .concat('248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.')
    .concat('612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.')
    .concat('583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z')*/

  const handleSubmit = (filter: IOrderFilter) => {
    setFilterValue(filter);
    getOrders({
      ...filter,
      isOrderAgency: defaultSend,
      conveyorId: transpSelected,
    });

    getOrdersMap({
      ...filter,
      isOrderAgency: defaultSend,
      conveyorId: transpSelected,
    });
  };

  const handleMotorizeChange = () => {
    openDrawer('DELIVERY_MOTORIZED_EDIT', 'Asignar motorizado');
  };

  const handleChangeProvince = (ubigeId: string) => {
    getOrders({
      ...filterValue,
      provinceCode: ubigeId,
      isOrderAgency: defaultSend,
      conveyorId: transpSelected,
    });

    getOrdersMap({
      ...filterValue,
      provinceCode: ubigeId,
      isOrderAgency: defaultSend,
      conveyorId: transpSelected,
    });

    setDefaultSelected(ubigeId);
  };

  const handleChangeTypeSend = (type: string) => {
    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: type,
      conveyorId: transpSelected,
    });

    getOrdersMap({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: type,
      conveyorId: transpSelected,
    });
    setDefaultSend(type);
  };

  useEffect(() => {
    if (orderUpdated || orderDeliveryUpdated) {
      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId: transpSelected,
      });

      getOrdersMap({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId: transpSelected,
      });

      setInfoWindow(false);
      setMotorized(undefined);
      setOrderMap(undefined);
    }
  }, [orderUpdated, orderDeliveryUpdated]);

  useEffect(() => {
    if (authUser.user.company.countryCode === 'PER') {
      if (authUser.user.role.name === 'SUPER_MASTER') {
        getTransporters();
        setHiddenTransp(false);
      } else if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        setHiddenTransp(true);
        setTranspSelected(`${authUser.user.conveyor?.id}`);
        if (authUser.user.conveyor?.id) {
          setConveyorSelected({
            id: authUser.user.conveyor.id,
            name: '',
            isConveyorPremium: false,
            coverages: [],
          });
        }
      }

      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId:
          authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'MASTER'
            ? `${authUser.user.conveyor?.id}`
            : transpSelected,
      });

      getOrdersMap({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId:
          authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'MASTER'
            ? `${authUser.user.conveyor?.id}`
            : transpSelected,
      });
    } else if (authUser.user.company.countryCode === 'BOL') {
      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId: transpSelected,
      });

      getOrdersMap({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        conveyorId: transpSelected,
      });
    }

    getMotorizeds(
      authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
        ? `${authUser.user.conveyor?.id}`
        : transpSelected,
    );
  }, []);

  useEffect(() => {
    setOrderDeliveryChecked([]);
  }, [orders]);

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: -12.04318,
    lng: -77.02824,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDRLmtYEKzjsAWuYDX-RbXTcUKjcVozF4E',
  });

  const handleInfoMapOpen = (o: IOrderDeliveryItemResponse) => {
    setMotorized(undefined);
    setInfoWindow(true);
    setOrderMap(o);
    setUbigeo(
      new google.maps.LatLng(
        parseFloat(o.shipping.lat),
        parseFloat(o.shipping.lng),
      ),
    );
  };

  const handleMotorizedChange = (value: string) => {
    const moto = motorizeds.find((it) => `${it.id}` === `${value}`);

    setMotorized(moto);
  };

  const handleChangeAssignmentMotorized = () => {
    if (motorized && orderMap) {
      const data: IOrderDeliveryCreateRequest[] = [
        {
          id: orderMap.orderDelivery?.id,
          orderId: orderMap.id,
          userId: motorized.id,
          method: 'Asignación manual por mapa',
          isActive: true,
          isAssigned: true,
          motorized: motorized.email,
          deliveryDate: orderMap.shipping.scheduleDate,
        },
      ];

      saveLogs('ORDER_MAP_ASSIGNED', data);
      saveOrderDelivery(data);
    }
  };

  useEffect(() => {
    if (response) {
      setOrderDeliveryUpdated(response);
    }
  }, [response]);

  const saveLogs = (action: string, orders: any) => {
    datadogLogs.logger.info(action, {
      action: action,
      orders: orders,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions,
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
    });
  };

  const handleCloseInfoWindow = () => {
    setInfoWindow(false);
    setMotorized(undefined);
    setOrderMap(undefined);
  };

  /* wht, blu #1976D2, red #FF5252, grn #4CAF50, ltblu #03A9F4, pink #E91E63, purple #9C27B0,
     ylw #FFEB3B, orange #FF9800*/

  /*
  const customMarker = (isAssigned: boolean, marker?: IMarker) => {
    return isAssigned === true ? {
      url: `http://maps.google.com/mapfiles/kml/paddle/${marker ? marker.icon : 'wht'}-circle.png`,
      scaledSize: new google.maps.Size(40, 40)
    } : {
      url: 'http://maps.google.com/mapfiles/kml/paddle/wht-circle.png',
      scaledSize: new google.maps.Size(40, 40)
    }
  }
  */

  const handleChangeTransporter = (id: string) => {
    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      conveyorId: id,
    });

    getOrdersMap({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      conveyorId: id,
    });

    if (transporters && transporters.length > 0) {
      const t = transporters.find((t) => `${t.id}` === id);

      if (t) {
        setConveyorSelected(t);
      }
    }

    getMotorizeds(id);

    setTranspSelected(id);
  };

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <span>Transportadora:</span>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <DeliveryFilterForm onChange={handleSubmit} />

          <Button
            type="primary"
            onClick={handleMotorizeChange}
            disabled={!orderDeliveryChecked?.length}
          >
            Asignar moto
          </Button>

          <span>Tipo envío:</span>
          <Select
            placeholder="T. Envío"
            style={{ minWidth: '130px' }}
            onChange={handleChangeTypeSend}
            value={defaultSend}
          >
            <Option key="0" value="FALSE">
              CONTRAENTREGA
            </Option>
            <Option key="1" value="TRUE">
              AGENCIA
            </Option>
          </Select>

          <span>Provincia:</span>
          <Select
            placeholder="Provincia"
            showSearch
            optionFilterProp="label"
            style={{ minWidth: '130px' }}
            onChange={handleChangeProvince}
            value={defaultSelected}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="ALL" value="ALL" label={'TODOS'}>
              TODOS
            </Option>
            {provinceList.map((key) => (
              <Option
                key={key.id_ubigeo}
                value={key.id_ubigeo}
                label={key.nombre_ubigeo}
              >
                {key.nombre_ubigeo}
              </Option>
            ))}
          </Select>
        </div>
      </PageHeader>

      <Flex>
        <div
          style={{
            minWidth: 240,
            height: 400,
            overflow: 'auto',
            marginLeft: '0.5rem',
          }}
        >
          <Flex align="center" gap={8} style={{ marginBottom: '.6rem' }}>
            <MotocycleIcon />
            <b>Distribución motorizados</b>
          </Flex>
          <Spin spinning={loading}>
            {!loading && orders?.length === 0 && <Empty />}
            {!!orders?.length && (
              <DeliveryResumen orders={orders} motorizeds={motorizeds} />
            )}
          </Spin>
        </div>

        {isLoaded && (
          <div style={{ position: 'relative', width: '100%' }}>
            <Spin spinning={loadingMap}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
              >
                {ordersMap.length > 0 ? (
                  <>
                    {ordersMap.map((o) => {
                      return (
                        <Marker
                          key={o.id}
                          options={{
                            icon: {
                              path: 'm8.075 23.52c-6.811-9.878-8.075-10.891-8.075-14'
                                .concat(
                                  '.52 0-4.971 4.029-9 9-9s9 4.029 9 9c0 3.629-1.264 4.64-8.',
                                )
                                .concat(
                                  '075 14.516-.206.294-.543.484-.925.484s-.719-.19-.922-.48l-.',
                                )
                                .concat(
                                  '002-.004zm.925-10.77c2.07 0 3.749-1.679 3.749-3.75s-1.679-3.',
                                )
                                .concat(
                                  '75-3.75-3.75-3.75 1.679-3.75 3.75c0 2.071 1.679 3.75 3.75 3.75z',
                                ),
                              fillColor:
                                o.isAssigned === true
                                  ? o.orderDelivery?.user?.markerIcon?.color
                                  : '#FFFFFF',
                              fillOpacity: 1,
                              strokeWeight: 1,
                              strokeColor: '#000000',
                              strokeOpacity: 1,
                              size: new google.maps.Size(100, 100),
                              scaledSize: new google.maps.Size(50, 50),
                            },
                          }}
                          //icon={customMarker(o.isAssigned === true, o.orderDelivery?.user?.markerIcon)}
                          onClick={() => handleInfoMapOpen(o)}
                          position={
                            new google.maps.LatLng(
                              parseFloat(o.shipping.lat),
                              parseFloat(o.shipping.lng),
                            )
                          }
                        />
                      );
                    })}

                    {infoWindow && (
                      <InfoWindow
                        position={ubigeo}
                        onCloseClick={() => handleCloseInfoWindow()}
                      >
                        <div>
                          {orderMap?.orderNumber}
                          <br></br>
                          Motorizado:{' '}
                          {orderMap?.isAssigned === true
                            ? orderMap?.orderDelivery?.user.email
                            : 'Sin asignar'}
                          <br></br>
                          <div>
                            <Select
                              loading={loadingMt}
                              style={{ width: '100%', marginTop: 4 }}
                              showSearch
                              optionFilterProp="label"
                              placeholder="Seleccionar motorizado"
                              options={motorizeds.map(
                                (e: IMotorizedResponse) => ({
                                  label: e.email,
                                  value: e.id,
                                }),
                              )}
                              onChange={handleMotorizedChange}
                            />
                            <Button
                              type="primary"
                              style={{ width: '100%', marginTop: 4 }}
                              loading={loadingSave}
                              onClick={() => handleChangeAssignmentMotorized()}
                            >
                              Guardar
                            </Button>
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </GoogleMap>
            </Spin>
          </div>
        )}
      </Flex>

      <DeliveryTable data={orders} loading={loading} />
    </>
  );
};

export default OrdersListContainer;
