import React, { useEffect } from 'react';
import { Button } from 'antd';
import { PageHeader } from '../../../../../components';
import { useModal } from 'react-modal-hook';
import { FaqEditContainer } from '..';
import { FaqMaintenanceTable } from '../../components';
import { useFrequentQuestions } from '../../../../../services/frequent-question-list/frequent-question.service';
import { IFrequentQuestion } from '../../../../../services/frequent-question-list/frequent-question.interface';

export const FaqMaintenanceContainer = () => {
  const { getQuestions, loading, questions } = useFrequentQuestions();
  const [selectedFaq, setSelectedFaq] = React.useState<IFrequentQuestion>();

  const [openDrawer, closeEditDrawer] = useModal(
    () => <FaqEditContainer faq={selectedFaq} onClose={closeEditDrawer} onFinish={getQuestions} />,
    [selectedFaq, getQuestions],
  );

  const hanleCreateFaq = () => {
    setSelectedFaq(undefined);
    openDrawer();
  };

  const handleEdit = (faq: IFrequentQuestion) => {
    setSelectedFaq(faq);
    openDrawer();
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      <PageHeader>
        <Button type="primary" onClick={hanleCreateFaq}>
          + Agregar
        </Button>
      </PageHeader>

      <FaqMaintenanceTable data={questions} loading={loading} onEdit={handleEdit} />
    </>
  );
};
