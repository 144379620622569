import { useAxios } from '../../hooks';
import { IFrequentQuestion } from '../frequent-question-list/frequent-question.interface';
import { IFrequentQuestionCreateSchema } from './frequent-question-create.schema';

export const useFrequentQuestionCreate = () => {
  const { execute, response, loading } = useAxios<IFrequentQuestion>();

  const createQuestion = (data: IFrequentQuestionCreateSchema) => {
    const payload: IFrequentQuestion = {
      ...data,
      position: Number(data.position),
      roles: data.roles.join(','),
    };

    return execute({
      method: 'POST',
      url: '/frequent-question',
      data: payload,
    });
  };

  return {
    createQuestion,
    loading,
    created: response,
  };
};
