import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { IProductCreateSchema } from '../../../../../services/product-create/product-create.schema';
import { useProductCreate } from '../../../../../services/product-create/product-create.service';
import { useProductStore } from '../../../../../services/product/product.store';
import { ProductEditForm } from '../../forms';

const ProductEditContainer = () => {
  const { createProduct, productCreated, loading } = useProductCreate();
  const { closeDrawer } = useDrawer();
  const { setProductUpdated } = useProductStore();

  const handleSubmit = (data: IProductCreateSchema) => {
    createProduct(data);
  };

  useEffect(() => {
    if (productCreated) {
      setProductUpdated(productCreated);
      closeDrawer();
    }
  }, [productCreated]);

  return (
    <>
      <Spin spinning={loading}>
        <ProductEditForm onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default ProductEditContainer;
