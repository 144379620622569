import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import s from './HowToUseButton.module.css';

export const HowToUseButton = () => {
  const navigate = useNavigate();

  const handleChangeQuestions = () => {
    navigate('/preguntas-frecuentes', { replace: false });
  };

  return (
    <Button
      type="text"
      color="primary"
      variant="filled"
      className={s['shake-button']}
      onClick={handleChangeQuestions}
    >
      🔥 ¿Cómo usar Aliclik? Click aquí
    </Button>
  );
};
