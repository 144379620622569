import { Spin } from 'antd';
import React, { ComponentType, FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthStore, useVerify } from '../services/auth/auth.service';
import { ROUTES } from './routes.param';

interface IPrivateRouteProps {
  Component: ComponentType;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({ Component }) => {
  const navigate = useNavigate();
  const { authUser } = useAuthStore();
  const { getVerify, loading } = useVerify();

  useEffect(() => {
    if (!authUser) {
      navigate(ROUTES.SIGN_IN.PATH);
    } else {
      getVerify();
    }
  }, [navigate]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '5rem 0' }}>
        <Spin />
      </div>
    );
  }

  return authUser ? <Component /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
