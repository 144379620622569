import React, { useState, useEffect } from 'react'
import { Affix, Button } from 'antd'
import { BulbOutlined } from '@ant-design/icons'
import './FloatingButton.css'

const FloatingButton = () => {
  const [showText, setShowText] = useState(false)
  const [buttonLoaded, setButtonLoaded] = useState(false)
  const [hovered, setHovered] = useState(false) // Añadido para controlar el hover del texto

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true)
      setButtonLoaded(true) // El texto y el botón se animan al mismo tiempo
    }, 1000) // Ambos comienzan después de 1 segundo

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Affix style={{ position: 'fixed', bottom: 40, right: 10, zIndex: 100 }}>
        <div className={`floating-container ${buttonLoaded ? 'shrink' : ''}`}>
          {showText && (
            <div
              className={`floating-text ${showText ? 'show' : ''}`}
              onClick={() => window.open('https://aliclik.app/faq', '_blank')}
              onMouseEnter={() => setHovered(true)} // Detecta cuando el mouse entra
              onMouseLeave={() => setHovered(false)} // Detecta cuando el mouse sale
              style={{ cursor: 'pointer' }}
            >
              ¿Cómo usar Aliclik?
            </div>
          )}
          <Button
            type='primary'
            shape='circle'
            size='large'
            className={`floating-button ${hovered ? 'hovered' : ''} ${buttonLoaded ? 'shrink' : 'enlarge'}`}
          >
            <a target="_blank" rel="noreferrer" href={'https://aliclik.app/faq'}>
              <BulbOutlined />
            </a>
          </Button>
        </div>
      </Affix>
    </>
  )
}

export default FloatingButton
