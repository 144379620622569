import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { coverageActions } from '../../state/coverage/coverage.actions'
import { ICoverageState } from '../../state/coverage/coverage.reducer'
import { ICoverageItemResponse } from './coverage-create.interface'
import { ICoverage } from '../location/location.interface'

export const useCoverageStore = () => {
  const dispatch = useDispatch()
  const { 
    coverageUpdated,
    coverageUbigeoUpdated
  } = useSelector((state: IRootState) => state.coverage as ICoverageState)

  const setCoverageUpdated = (data: ICoverageItemResponse) => {
    dispatch(coverageActions.setCoverageUpdated(data))
  }

  const setCoverageUbigeoUpdated = (data?: ICoverage) => {
    dispatch(coverageActions.setCoverageUbigeoSelected(data))
  }

  return {
    setCoverageUpdated,
    coverageUpdated,
    setCoverageUbigeoUpdated,
    coverageUbigeoUpdated
  }
}
