import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { ISettingResponse, ITkIntegrationResponse } from './setting.interface'
import { mapToSettingRequest } from './setting.mapper'
import { ISettingSchema } from './setting.schema'

export const useSaveSetting = () => {
  const { execute, loading } = useAxios()

  const saveSetting = (setting: ISettingSchema) => {
    const data = mapToSettingRequest(setting)

    execute({
      method: 'POST',
      url: '/setting',
      data,
    })
  }

  return {
    saveSetting,
    loading,
  }
}

export const useGetSetting = () => {
  const { execute, response: setting, loading } = useAxios<ISettingResponse>()

  const getSetting = (companyId?: string) => {
    execute({
      method: 'GET',
      url: '/setting',
      params: {
        companyId: companyId,
      },
    })
  }

  return {
    getSetting,
    setting,
    loading,
  }
}

export const useTkEmidica = () => {
  const { execute, response: tk, loading } = useAxios<ITkIntegrationResponse>()
  const { authUser } = useAuthStore()

  const generateToken = (companyId?: string) => {
    execute({
      method: 'POST',
      url: '/auth/token-integration',
      data: {
        companyId: (companyId || authUser.user.company.id),
      },
    })
  }

  return {
    generateToken,
    tk,
    loadingtk: loading,
  }
}
