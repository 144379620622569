import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { ISkuUpdateSchema } from '../../../../../services/product-create/product-create.schema';
import { useSkuUpdate } from '../../../../../services/product-create/product-create.service';
import { useProductStore } from '../../../../../services/product/product.store';
import { SkuEditForm } from '../../forms';

const SkuEditContainer = () => {
  const { updateSku, skuUpdated, loading } = useSkuUpdate();
  const { closeDrawer } = useDrawer();
  const { setProductUpdated } = useProductStore();

  const handleSubmit = (data: ISkuUpdateSchema) => {
    updateSku(data);
  };

  useEffect(() => {
    if (skuUpdated) {
      setProductUpdated(skuUpdated);
      closeDrawer();
    }
  }, [skuUpdated]);

  return (
    <>
      <Spin spinning={loading}>
        <SkuEditForm onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default SkuEditContainer;
