import React from 'react';
import { Button, Card, Col, Image, Layout, Row, Typography } from 'antd';
import logo from './../../../SignIn/ic_aliclik.png';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routes.param';
import { Content, Footer, Header } from 'antd/lib/layout/layout';

const { Title } = Typography;

const LandingContainer = () => {
  const navigate = useNavigate();

  const actionLogin = () => {
    navigate(ROUTES.SIGN_IN.PATH, { replace: false });
  };

  return (
    <>
      <Layout>
        <Header
          style={{
            background: '#00000020',
            padding: '0 20px',
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 1000,
            borderBottom: '1px solid #ddd',
          }}
        >
          <div
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Image
              src={logo}
              style={{ width: '40px', height: '40px' }}
              preview={false}
            />

            <Button type="primary" shape="round" onClick={() => actionLogin()}>
              Iniciar sesión
            </Button>
          </div>
        </Header>

        <Content
          style={{
            padding: '50px',
            textAlign: 'center',
            paddingTop: '16px',
            marginTop: '64px',
          }}
        >
          <Image
            src={logo}
            preview={false}
            style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
          />
          <Title level={2} style={{ margin: '20px 0' }}>
            La nueva manera para recibir y enviar pagos ecommerce
          </Title>
          <Title level={5} style={{ margin: '20px 0' }}>
            Aliclik es una tecnología de nueva generación que le permite a
            empresas, emprendedores y comerciantes electrónicos para recibir
            pagos a cuentas bancarias. Fácil, rápido y seguro.
          </Title>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => actionLogin()}
          >
            Solicitar demo
          </Button>
        </Content>

        <div style={{ textAlign: 'center' }}>
          <Title level={2} style={{ margin: '20px 0' }}>
            Habilitamos nuevas oportunidades para comerciantes electrónicos
          </Title>
          <Title level={5} style={{ margin: '20px 0' }}>
            Aliclik facilita el ciclo entero de gestión de dinero: Desde el
            recaudo por cada venta hasta la entrega de fondos a cada empresa,
            emprendedor y comerciante electrónico.
          </Title>
        </div>

        <div style={{ padding: '50px 20px' }}>
          <Row gutter={16}>
            <Col span={8} xs={24} md={8} xl={8}>
              <Card title="Generar cobros" bordered={false}>
                Recauda en cualquier momento a través de los agentes.
              </Card>
            </Col>
            <Col span={8} xs={24} md={8} xl={8}>
              <Card title="Automatizar desembolsos" bordered={false}>
                Generar pagos hacia cualquier cuenta en segundas.
              </Card>
            </Col>
            <Col span={8} xs={24} md={8} xl={8}>
              <Card title="Transacciones múltiples" bordered={false}>
                Generar flujos de trabajo completamente automatizados.
              </Card>
            </Col>
          </Row>
        </div>

        <Footer style={{ textAlign: 'center', background: '#000' }}>
          <Title level={5} style={{ color: '#FFF' }}>
            Copyright ©️ 2023 ALICLIK SYSTEM S.A.C (RUC : 20611293365)
          </Title>
        </Footer>
      </Layout>
    </>
  );
};

export default LandingContainer;
