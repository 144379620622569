import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Tooltip,
  Row,
  Col,
  Space,
} from 'antd';
import { ISettingResponse } from '../../../../services/setting/setting.interface';
import { ISettingSchema } from '../../../../services/setting/setting.schema';
import { useUserSupSeller } from '../../../../services/order-create/order.edit';
import {
  BankOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useTkEmidica } from '../../../../services/setting/setting.service';
import { useCompanyStore } from '../../../../services/company-create/company-create.service';
import { BANKS_FOR_PAYMENTS } from '../../constants';
import { CURRENCY_LIST } from '../../../../config/data.params';

interface IConfigOrderFormProps {
  initialValues: ISettingResponse | undefined;
  onSubmit: (data: ISettingSchema) => void;
}

const COUNTRY_LIST_CUSTOM = [
  { name: 'Perú', code: 'PER', phoneCode: '+51' },
  { name: 'Colombia', code: 'COL', phoneCode: '+57' },
  { name: 'Bolivia', code: 'BOL', phoneCode: '+591' },
  { name: 'Chile', code: 'CHL', phoneCode: '+56' },
  { name: 'Brasil', code: 'BRA', phoneCode: '+55' },
  { name: 'México', code: 'MEX', phoneCode: '+52' },
  { name: 'Ecuador', code: 'ECU', phoneCode: '+593' },
  { name: 'Argentina', code: 'ARG', phoneCode: '+54' },
];

const ConfigOrderForm: FC<IConfigOrderFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const [form] = Form.useForm<ISettingSchema>();
  const [filteredBankList, setFilteredBankList] = useState(BANKS_FOR_PAYMENTS);
  const [phonePrefix, setPhonePrefix] = useState('+51');
  const { isUser } = useUserSupSeller();
  const { companyGlobal } = useCompanyStore();
  const { generateToken, loadingtk, tk } = useTkEmidica();

  const countryCodeValue = Form.useWatch(['countryCode'], form);
  const codeBankValue = Form.useWatch(['codeBank'], form);
  const isShowAccountFields =
    codeBankValue === 'BCP' || codeBankValue === 'IBK';
  const isBinanceShow = codeBankValue === 'BIN';
  const isPaypalShow = codeBankValue === 'PAYPAL';

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        companyId: initialValues.companyId,
        companyPrefix: initialValues.companyPrefix,
        affiliateCode: `https://aliclik.app/ALICLIK-${initialValues?.companyId}`,
        currencyCode: initialValues.currency?.code,
        currency: initialValues.currency,
        countryCode: initialValues.country?.code,
        country: initialValues.country,
        phone: initialValues.phone,
        codeBank: initialValues.codeBank,
        nameBank: initialValues.nameBank,
        accountNumber: initialValues.accountNumber,
        cci: initialValues.cci,
        holderName: initialValues.holderName,
        emailTransfer: initialValues.emailTransfer,
        documentHolderAccount: initialValues.documentHolderAccount,
        businessName: initialValues.businessName,
        businessDocument: initialValues.businessDocument,
        paypalEmail: initialValues.paypalEmail,
        binanceEmail: initialValues.binanceEmail || '',
        binanceId: initialValues.binanceId || '',
        binanceUser: initialValues.binanceUser || '',
        tk: initialValues.company.tokenIntegration || ''
      })
 
    }
  }, [initialValues]);

  useEffect(() => {
    if (tk) {
      form.setFieldValue(['tk'], tk.tokenIntegration);
    }
  }, [tk]);

  const generateTkEmidica = () => {
    generateToken(companyGlobal ? `${companyGlobal.id}` : '');
  };

  const handleCopyTkText = () => {
    const linkText = form.getFieldValue('tk');

    if (linkText) {
      try {
        navigator.clipboard.writeText(linkText);
        message.success('token copiado');
      } catch (error) {
        message.error('Error al copiar texto');
      }
    }
  };

  const handleCopyText = () => {
    const linkText = form.getFieldValue('affiliateCode');

    if (linkText) {
      try {
        navigator.clipboard.writeText(linkText);
        message.success('Link copiado');
      } catch (error) {
        message.error('Error al copiar texto');
      }
    }
  };

  const handleCurrencyCodeChange = (value: string) => {
    const currency = CURRENCY_LIST.find((e) => e.code === value);

    form.setFieldsValue({ currency });
  };

  const handleCountryCodeChange = (value: string) => {
    const country = COUNTRY_LIST_CUSTOM.find((e) => e.code === value);

    if (country) {
      setPhonePrefix(country.phoneCode);
      setFilteredBankList(
        BANKS_FOR_PAYMENTS.filter(
          (bank) =>
            bank.availableCountry.includes(country.code) ||
            bank.availableCountry.includes('ALL'),
        ),
      );
      form.setFieldsValue({ country, codeBank: undefined });
    }
  };

  const handleCodeBankChange = (value: string) => {
    const bank = BANKS_FOR_PAYMENTS.find((b) => b.code === value);

    form.setFieldsValue({ nameBank: bank?.value });
  };

  return (
    <Form
      name="basic"
      autoComplete="off"
      layout="vertical"
      form={form}
      labelWrap
      onFinish={onSubmit}
    >
      <h2 style={{ marginTop: 10 }}>Configuración</h2>

      <Form.Item>
        <Alert
          message="Por favor es obligatorio completar todos los datos para que puedan
        agendar sus pedidos con normalidad y se continúen haciendo los depósitos de sus liquidaciones"
          type="info"
        />
      </Form.Item>

      <Form.Item<ISettingSchema> name={['companyId']} hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item<ISettingSchema>
        name={['companyPrefix']}
        label="Prefijo para órdenes"
        required
        rules={[{ required: true, message: 'Campo obligatorio' }]}
      >
        <Input type="text" disabled />
      </Form.Item>

      <Space.Compact>
        <Form.Item<ISettingSchema>
          label="Link afiliado"
          name={['affiliateCode']}
          style={{ display: 'inline-block', width: '320px' }}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label=""
          style={{
            marginTop: 26,
          }}
        >
          <Button onClick={handleCopyText}>
            <CopyOutlined />
          </Button>
        </Form.Item>
      </Space.Compact>

      <h3>
        <b>Datos para Negocio</b>
      </h3>

      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item<ISettingSchema>
            label="Currency"
            name={['currencyCode']}
            style={{ width: '100%' }}
            required
            rules={[{ required: true, message: 'Seleccione una moneda' }]}
          >
            <Select
              placeholder="Seleccione una opción"
              options={CURRENCY_LIST.map((e) => ({
                label: e.name,
                value: e.code,
              }))}
              onChange={handleCurrencyCodeChange}
            />
          </Form.Item>

          <Form.Item<ISettingSchema> name={['currency']} hidden>
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item<ISettingSchema>
            label="País"
            name={['countryCode']}
            style={{ width: '100%' }}
            required
            rules={[{ required: true, message: 'Seleccione un país' }]}
          >
            <Select
              placeholder="Seleccione una opción"
              onChange={handleCountryCodeChange}
              options={COUNTRY_LIST_CUSTOM.map((e) => ({
                label: e.name,
                value: e.code,
              }))}
            />
          </Form.Item>

          <Form.Item<ISettingSchema> name={['country']} hidden>
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item<ISettingSchema>
            label="Teléfono"
            name="phone"
            required
            rules={[
              {
                required: true,
                pattern: /^[0-9]+$/,
                message: 'Solo se permiten números',
              },
            ]}
            style={{ width: '100%' }}
          >
            <Input addonBefore={phonePrefix} type="number" />
          </Form.Item>
        </Col>
      </Row>

      <h3>
        <BankOutlined /> Datos de pago
      </h3>

      <Form.Item<ISettingSchema>
        label="Banco"
        name={['codeBank']}
        required
        rules={[{ required: true, message: 'Seleccione un banco' }]}
      >
        <Select
          placeholder="Seleccione"
          disabled={!countryCodeValue}
          options={filteredBankList.map((e) => ({
            label: `${e.value} / ${e.code}`,
            value: e.code,
          }))}
          onChange={handleCodeBankChange}
        />
      </Form.Item>

      <Form.Item<ISettingSchema> name={['nameBank']} hidden>
        <Input />
      </Form.Item>

      {isShowAccountFields && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item<ISettingSchema>
              label={
                <span>
                  Número de cuenta
                  <Tooltip title="N° de cuenta: 47505215317055">
                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              }
              name={['accountNumber']}
              style={{ width: '100%' }}
              required={isShowAccountFields}
              rules={[
                {
                  required: isShowAccountFields,
                  pattern: /^[0-9]+$/,
                  message: 'Solo se permiten números',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item<ISettingSchema>
              label={
                <span>
                  Número de cuenta interbancaria (CCI)
                  <Tooltip title="CCI: 00247510521531705520">
                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              }
              name={['cci']}
              required={isShowAccountFields}
              style={{ width: '100%' }}
              rules={[
                {
                  required: isShowAccountFields,
                  pattern: /^[0-9]+$/,
                  message: 'Solo se permiten números',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
      )}

      {isBinanceShow && (
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item<ISettingSchema>
              label="Correo registrado en Binance"
              name={['binanceEmail']}
              required={isBinanceShow}
              rules={[
                {
                  required: isBinanceShow,
                  type: 'email',
                  message: 'Ingrese un correo válido',
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item<ISettingSchema>
              label="ID de Binance"
              name={['binanceId']}
              required={isBinanceShow}
              rules={[
                {
                  required: isBinanceShow,
                  pattern: /^[0-9]+$/,
                  message: 'Solo se permiten números',
                },
              ]}
            >
              <Input
                type="text"
                maxLength={9}
                onChange={(e) => {
                  const { value } = e.target;
                  const filteredValue = value.replace(/[^0-9]/g, ''); // Remover cualquier cosa que no sea un número

                  if (filteredValue.length <= 9) {
                    form.setFieldsValue({ binanceId: filteredValue });
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item<ISettingSchema>
              label="Usuario Binance"
              name={['binanceUser']}
              required={isBinanceShow}
              rules={[
                {
                  required: isBinanceShow,
                  pattern: /^[a-zA-Z0-9]+$/,
                  message: 'Solo se permiten números y letras',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
      )}

      {isPaypalShow && (
        <Form.Item<ISettingSchema>
          label="Correo de PayPal"
          name={['paypalEmail']}
          required={isPaypalShow}
          rules={[
            {
              required: isPaypalShow,
              type: 'email',
              message: 'Ingrese un correo válido',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
      )}

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item<ISettingSchema>
            label="Nombre completo del titular"
            name={['holderName']}
            required
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el nombre del titular de la cuenta',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item<ISettingSchema>
            label="Correo para constancia transferencia"
            name={['emailTransfer']}
            required
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Por favor ingrese un correo válido',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item<ISettingSchema>
            label="RUC/DNI titular de la cuenta"
            name={['documentHolderAccount']}
            required
            rules={[
              {
                required: true,
                pattern: /^[0-9]+$/,
                message: 'Solo se permiten números',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {isUser && (
        <>
          <Form.Item<ISettingSchema>
            label="Costo de envío para motorizados"
            name={['amountDelivery']}
            hidden
          >
            <Input type="number" />
          </Form.Item>
        </>
      )}

      <h3>Datos para comprobantes</h3>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="RUC (DNI si no cuentas RUC)"
            name="businessDocument"
            required
            rules={[
              {
                required: true,
                pattern: /^[0-9]+$/,
                message: 'Solo se permiten números',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Razón social"
            name="businessName"
            required
            rules={[
              {
                required: true,
                message: 'Por favor ingrese la razón social',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="SpreadSheet ID"
        name={['spreadsheetOrder', 'spreadSheetId']}
        hidden
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Sheet name"
        name={['spreadsheetOrder', 'sheetName']}
        hidden
      >
        <Input type="text" />
      </Form.Item>

      {(initialValues &&
        initialValues.company.tokenIntegration &&
        initialValues.company.tokenIntegration.length > 0) ||
      tk ? (
        <>
          <h3>
            <b>Integración Emidica</b>
          </h3>

          <Form.Item>
            <Form.Item
              label="Token Emidica"
              name={['tk']}
              style={{ display: 'inline-block', width: 'calc(90% - 8px)' }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={' '}
              style={{ display: 'inline-block', width: 'calc(10% - 8px)' }}
            >
              <Button onClick={handleCopyTkText}>
                <CopyOutlined />
              </Button>
            </Form.Item>
          </Form.Item>
        </>
      ) : (
        <>
          <h3>
            <b>Generar token integración</b>
          </h3>

          <Button
            type="default"
            shape="round"
            style={{ backgroundColor: '#000' }}
            onClick={() => generateTkEmidica()}
            loading={loadingtk}
          >
            <img
              src="https://shop-image.cloud/img/landing/logo.svg"
              width={120}
              alt="Emidica"
            ></img>
          </Button>
        </>
      )}

      <Divider />

      <Button type="primary" htmlType="submit" style={{ marginBottom: 48 }}>
        Guardar
      </Button>
    </Form>
  );
};

export default ConfigOrderForm;
