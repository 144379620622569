import { ICoverageItemResponse } from '../../services/coverage-create/coverage-create.interface'
import { ICoverage } from '../../services/location/location.interface'

export const actionTypes = {
  SET_COVERAGE_SELECTED: 'SET_COVERAGE_SELECTED',
  SET_COVERAGE_UPDATED: 'SET_COVERAGE_UPDATED',
  SET_COVERAGE_UBIGEO_UPDATED: 'SET_COVERAGE_UBIGEO_UPDATED'
}

const setCoverageSelected = (data: ICoverageItemResponse | null) => ({
  type: actionTypes.SET_COVERAGE_SELECTED,
  payload: data,
})

const setCoverageUpdated = (data: ICoverageItemResponse) => ({
  type: actionTypes.SET_COVERAGE_UPDATED,
  payload: data,
})

const setCoverageUbigeoSelected = (data: ICoverage | undefined) => ({
  type: actionTypes.SET_COVERAGE_UBIGEO_UPDATED,
  payload: data,
})

export const coverageActions = {
  setCoverageSelected,
  setCoverageUpdated,
  setCoverageUbigeoSelected
}
