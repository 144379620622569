import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { CompanyChangeContainer } from '../../../../../containers';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import {
  useProductsAll,
  useProductsWarehouseAll,
} from '../../../../../services/product/product.service';
import { useProductStore } from '../../../../../services/product/product.store';
import { ProductsListTable } from '../../components';
import { useProductEdit } from '../../../../../services/product/product.edit';
import { useCategories } from '../../../../../services/category/category.service';

const { Search } = Input;
const { Option } = Select;

const ProductsMaintContainer = () => {
  const { openDrawer } = useDrawer();
  const { authUser } = useAuthStore();
  const { getProducts, products, loading, count, currentPage } =
    useProductsAll();
  const {
    getProductsToWarehouse,
    productsWar,
    loadingProds,
    countProds,
    currentPageProds,
  } = useProductsWarehouseAll();
  const { getCategories, categories, loadingCategory } = useCategories();
  const { setProductSelected, productUpdated } = useProductStore();
  const { companyGlobal } = useCompanyStore();
  const { isEditable } = useProductEdit();
  const [categorySelected, setCategorySelected] = useState('0');
  const [searchProd, setSearchProd] = useState('');

  const handleOpenDrawer = () => {
    setProductSelected(null);
    openDrawer('PRODUCT_EDIT', 'Agregar producto');
  };

  const handlePagination = (page: number) => {
    if (authUser.user.role.name === 'STORE') {
      getProductsToWarehouse(`${page}`, searchProd, categorySelected);
    } else {
      getProducts(
        companyGlobal ? `${companyGlobal.id}` : '',
        true,
        `${page}`,
        searchProd,
        categorySelected,
      );
    }
  };

  useEffect(() => {
    if (productUpdated) {
      if (authUser.user.role.name === 'STORE') {
        getProductsToWarehouse('1', searchProd, categorySelected);
      } else {
        getProducts(
          companyGlobal ? `${companyGlobal.id}` : '',
          true,
          '1',
          searchProd,
          categorySelected,
        );
      }
    }
  }, [productUpdated]);

  useEffect(() => {
    getCategories();

    if (
      companyGlobal &&
      (authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER' ||
        authUser.user.role.name === 'SUPER_MASTER')
    ) {
      getProducts(
        companyGlobal ? `${companyGlobal.id}` : '',
        true,
        '1',
        searchProd,
        categorySelected,
      );
    } else if (
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      getProducts(
        `${authUser.user.company.id}`,
        true,
        '1',
        searchProd,
        categorySelected,
      );
    } else if (authUser.user.role.name === 'STORE') {
      getProductsToWarehouse('1', searchProd, categorySelected);
    }
  }, [companyGlobal]);

  const onSearch = (value: string) => {
    setSearchProd(value);
    if (authUser.user.role.name === 'STORE') {
      getProductsToWarehouse('1', value, categorySelected);
    } else {
      getProducts(
        companyGlobal ? `${companyGlobal.id}` : '',
        true,
        '1',
        value,
        categorySelected,
      );
    }
  };

  const handleChangeCategory = (value: string) => {
    setCategorySelected(value);

    if (authUser.user.role.name === 'STORE') {
      getProductsToWarehouse('1', searchProd, value);
    } else {
      getProducts(
        companyGlobal ? `${companyGlobal.id}` : '',
        true,
        '1',
        searchProd,
        value,
      );
    }
  };

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          {authUser.user.role?.permissions?.product_company_change ? (
            <>
              <CompanyChangeContainer isAll={false} />
            </>
          ) : null}

          { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' || 
            authUser.user.role.name === 'STORE' || authUser.user.role.name === 'SUPER_MASTER' ? 
            <>
              <Button type="primary" onClick={handleOpenDrawer}>
                Agregar producto
              </Button>
            </> : null
          }

          

          <Search
            placeholder="Buscar producto"
            onSearch={onSearch}
            enterButton
            style={{ width: 304 }}
          />

          <h4 style={{ marginLeft: '12px', alignSelf: 'center' }}>
            Categorías:{' '}
          </h4>
          <Select
            optionFilterProp="label"
            loading={loadingCategory}
            style={{ minWidth: '130px' }}
            onChange={handleChangeCategory}
            value={categorySelected}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="0" value="0" label={'Todos'}>
              Todos
            </Option>
            {categories.map((c) => {
              return (
                <>
                  <Option key={c.id} value={c.id} label={c.name}>
                    {c.name}
                  </Option>
                </>
              );
            })}
          </Select>
        </div>
      </PageHeader>

      <ProductsListTable
        products={
          authUser.user.role.name === 'STORE'
            ? productsWar
              ? productsWar
              : []
            : products
              ? products
              : []
        }
        loading={authUser.user.role.name === 'STORE' ? loadingProds : loading}
        isEditable={isEditable}
        handlePagination={handlePagination}
        count={authUser.user.role.name === 'STORE' ? countProds : count}
        currentPage={
          authUser.user.role.name === 'STORE' ? currentPageProds : currentPage
        }
      />
    </>
  );
};

export default ProductsMaintContainer;
