import { useAxios } from '../../hooks';
import { IFrequentQuestion } from './frequent-question.interface';

export const useFrequentQuestions = () => {
  const { execute, response, loading } = useAxios<IFrequentQuestion[]>();

  const getQuestions = (args?: { countryCode?: string; isActive?: boolean }) => {
    execute({
      method: 'GET',
      url: '/frequent-question',
      params: {
        countryCode: args?.countryCode,
        isActive: args?.isActive,
      },
    });
  };

  return {
    getQuestions,
    loading,
    questions: response?.length ? response : [],
  };
};
