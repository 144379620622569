import React from 'react';
import { useDrawer } from '../../../../contexts/DrawerContext';
import { useSignUpCreate } from '../../../../services/sign-up/sign-up.service';
import { ISignUpSchema } from '../../../../services/sign-up/sign-up.schema';
import { SignUpForm } from '../../forms';
import { useSignupStore } from '../../store/signup.store';

const SignUpContainer = () => {
  const { openDrawer } = useDrawer();
  const { setCreateNewAccountRequest } = useSignupStore();
  const { preCreateNewAccount, loading } = useSignUpCreate();

  const handleSubmit = (data: ISignUpSchema) => {
    setCreateNewAccountRequest(data);
    preCreateNewAccount(data).then(() => {
      openDrawer('VERIFICATION_EMAIL', 'Verificación de correo');
    });
  };

  // useEffect(() => {
  //   openDrawer('VERIFICATION_EMAIL', 'Verificación de correo');
  // }, []);

  return <SignUpForm loading={loading} onSubmit={handleSubmit} />;
};

export default SignUpContainer;
