import React, { FC } from 'react';
import { Table, TableColumnsType, Tag } from 'antd';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_STATUS_LABEL,
  ORDER_STATUS_LIST,
} from '../../../../../../services/order-create/order.params';
import { CALL_STATUS_LABEL } from '../../../../../../services/pre-order/pre-order.params';
import { formatDateUTC } from '../../../../../../utils/date';
import {
  IOrderDeliveryGroupLocationResponse,
  IOrderDeliveryItemResponse,
} from '../../../../../../services/order-motorized/order-motorized.interface';
import { useOrderStore } from '../../../../../../services/order-list/order.store';
import { SendOutlined } from '@ant-design/icons';
import { useModal } from '../../../../../../contexts/ModalContext';
import DeliveryActionIcons from '../../DeliveryActionIcons/DeliveryActionIcons';

interface IDeliveryTableLevel3Props {
  record: IOrderDeliveryGroupLocationResponse;
}

const DeliveryTableLevel3: FC<IDeliveryTableLevel3Props> = ({ record }) => {
  const { setOrderDeliveryChecked, setOrderDeliveries } = useOrderStore();
  const { openModal } = useModal();

  const handleOrderDeliveries = (order: IOrderDeliveryItemResponse) => {
    setOrderDeliveries(order);
    openModal('ROUTE_ORDER_DELIVERIES', 'Historial de entregas');
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: IOrderDeliveryItemResponse[],
    ) => {
      setOrderDeliveryChecked(selectedRows);
    },
  };

  const columnsLevel3: TableColumnsType<IOrderDeliveryItemResponse> = [
    {
      title: 'Order',
      dataIndex: 'id',
      width: 120,
      render: (_, order) => order.orderNumber,
    },
    {
      title: 'Ubicación',
      dataIndex: 'comunication',
      width: 50,
      render: (_, order) => <DeliveryActionIcons order={order} />,
    },
    {
      title: 'Entregas',
      dataIndex: 'delivery',
      width: 80,
      render: (_, order) =>
        order.orderDeliveries.length > 0 ? (
          <>
            <a onClick={() => handleOrderDeliveries(order)}>
              <SendOutlined
                style={{
                  fontSize: '1.1rem',
                  margin: '0 .25rem',
                  cursor: 'pointer',
                }}
              />
            </a>
          </>
        ) : (
          'No asignado'
        ),
    },
    {
      title: 'Trazabilidad',
      width: 160,
      render: (
        _,
        {
          callStatus,
          isOrderAgency,
          warehouseName,
          status,
          rescheduledNumber,
          dispatchGuide,
          dispatchStatus,
        },
      ) => (
        <>
          <Tag color={CALL_STATUS_LABEL[callStatus]?.color}>
            {CALL_STATUS_LABEL[callStatus]?.label}
          </Tag>
          <Tag color={isOrderAgency ? '#FFB74D' : '#00796B'}>
            {isOrderAgency ? 'Agencia' : 'Contraentrega'}
          </Tag>
          <Tag color="#673AB7" hidden={warehouseName ? false : true}>
            {warehouseName}
          </Tag>
          <Tag color={ORDER_STATUS_LABEL[status]?.color}>
            {ORDER_STATUS_LABEL[status]?.label}
          </Tag>
          {status === ORDER_STATUS_LIST.PENDING_DELIVERY && rescheduledNumber === 0 ? <></> : <>
            <Tag color="#FB8C00">
              {`#Visitas: ${rescheduledNumber + 1}`}
            </Tag>
          </>}
          
          {dispatchGuide ? (
            <>
              <Tag color="#FF4081">{`#Guía: ${dispatchGuide.guideNumber}`}</Tag>
            </>
          ) : null}
          <Tag color="#757575">
            {ORDER_DISPATCH_STATUS_LABEL[dispatchStatus].label}
          </Tag>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 100,
      align: 'right',
      render: (_, { currency, total, totalPayment }) => (
        <>
          <b>
            {currency?.symbol} {total}
          </b>
          <br />
          {currency?.symbol} {totalPayment}
        </>
      ),
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Products',
      dataIndex: 'orderDetails',
      width: 240,
      render: (_, { orderDetails }) => (
        <>
          {orderDetails?.map((order) => (
            <div key={order.id}>
              {order.quantity} {order.sku.product?.name}
              {order.sku.skuOptions
                ?.filter((e) => e.option?.name !== '_DEFAULT')
                .map((op) => (
                  <span key={op.id}>
                    <span> / </span>
                    {op.option?.name}
                  </span>
                ))}
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Nota',
      dataIndex: 'note',
      width: 140,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      width: 120, align: 'center',
      render: (_, { customerHistory }) => customerHistory?.phone,
    },
    {
      title: 'Nombres',
      dataIndex: 'name',
      width: 100,
      render: (_, { customerHistory }) => customerHistory?.name,
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      width: 160,
      render: (_, { customerHistory }) => customerHistory?.lastName,
    },
    {
      title: 'Direccion 1',
      dataIndex: 'address1',
      width: 240,
      render: (_, { shipping }) => (
        <>
          {shipping?.address1} / {shipping?.address2} / {shipping?.reference}
          <br />
          {shipping?.lat},{shipping?.lng}
        </>
      ),
    },
    {
      title: 'Fechas', align: 'center', width: 200,
      dataIndex: 'scheduleDate',
      render: (_, { shipping }) => <>
        F. Despacho: {formatDateUTC(shipping?.dispatchDate)}
        <br />
        F. Entrega: {formatDateUTC(shipping?.scheduleDate)}
        <br />
      </>,
    },
    {
      title: 'Motorizado',
      dataIndex: 'orderDelivery',
      width: 140,
      align: 'center',
      render: (_, { orderDelivery, isAssigned }) =>
        isAssigned === true ? orderDelivery?.user?.email : '',
    },
    /*{
      title: 'Cobrado', dataIndex: 'totalPayment', width: 140, align: 'right',
      render: (_, { currency, totalPayment }) => totalPayment ? <><b>{currency?.symbol} {totalPayment}</b></> : null,
      sorter: (a, b) => a.totalPayment - b.totalPayment,
    },*/
    {
      title: 'Costo envío',
      dataIndex: 'shippingCost',
      width: 140,
      align: 'right',
      render: (_, { currency, shippingCost }) =>
        shippingCost ? (
          <>
            <b>
              {currency?.symbol} {shippingCost}
            </b>
          </>
        ) : null,
      sorter: (a, b) => a.shippingCost - b.shippingCost,
    },
  ];

  return (
    <>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="id"
        columns={columnsLevel3}
        dataSource={record.orders}
        pagination={false}
        scroll={{
          x: 2000,
        }}
      />
    </>
  );
};

export default DeliveryTableLevel3;
