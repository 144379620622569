import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IRoutePath, ROUTES } from './routes.param';
import { Layout, SidebarApp } from '../components';

export const RouterConfig = () => {
  const publicRoutes = Object.values(ROUTES)?.filter((r) => r.PUBLIC);
  const privateRoutes = Object.values(ROUTES)?.filter((r) => !r.PUBLIC);

  const renderRoute = (route: IRoutePath, key: number) => {
    if (route.CHILDREN) {
      const children = Object.values(route.CHILDREN);

      return (
        <Route
          key={`${key}-${route.PATH}`}
          path={route.PATH}
          element={route.ELEMENT || <SidebarApp />}
        >
          {children.map((childRoute: IRoutePath, index: number) => (
            <Route
              key={`${key}-${index}-${childRoute.PATH}`}
              path={childRoute.PATH}
              element={childRoute.ELEMENT}
            />
          ))}
        </Route>
      );
    }

    return (
      <Route
        key={`${key}-${route.PATH}`}
        path={route.PATH}
        element={route.ELEMENT}
      />
    );
  };

  return (
    <Routes>
      <Route path="/">
        <Route
          path="/"
          element={<Navigate to={ROUTES.SIGN_IN.PATH} replace />}
        />

        {/* Dynamic routes */}
        {publicRoutes.map((route, index) => renderRoute(route, index))}

        <Route element={<PrivateRoute Component={Layout} />}>
          {privateRoutes.map((route, index) => renderRoute(route, index))}
        </Route>
        {/* End dynamic routes */}
      </Route>

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
