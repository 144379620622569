/* eslint-disable max-len */
import React, { FC } from 'react';
import { Badge, Progress, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeliveryTableLevel2 } from './parts';
import { blue } from '@ant-design/colors';
import { IOrderDeliveryResponse } from '../../../../../services/order-motorized/order-motorized.interface';

interface IOrdersTableProps {
  data: IOrderDeliveryResponse[] | undefined;
  loading: boolean;
}

const OrdersTable: FC<IOrdersTableProps> = ({ data, loading }) => {
  const columnsLevel1: ColumnsType<IOrderDeliveryResponse> = [
    {
      title: 'Motorizado',
      dataIndex: 'motorized',
      width: 360,
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      width: 150,
      align: 'right',
      render: (_, order) => (
        <>
          {order.currency?.symbol} {order.totalAmount}
          <Progress
            style={{ marginLeft: '.5rem', width: '50px' }}
            percent={order.ratioAmount}
            size="small"
            showInfo={false}
            strokeColor={blue[5]}
          />
        </>
      ),
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Pedidos',
      dataIndex: 'totalOrder',
      render: (_, order) => (
        <>
          <Badge count={order.totalOrder} style={{ backgroundColor: '#888' }} />
        </>
      ),
      sorter: (a, b) => a.totalOrder - b.totalOrder,
    },
  ];

  return (
    <>
      <Table
        rowKey="motorized"
        columns={columnsLevel1}
        dataSource={data}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={loading}
        expandable={{
          expandedRowRender: DeliveryTableLevel2,
          rowExpandable: (record) => !!record.orders?.length,
        }}
      />
    </>
  );
};

export default OrdersTable;
