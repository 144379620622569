/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { formatDate } from '../../../../../utils/date';
import { OrderAgencyEditForm } from '../../forms';
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { useOrderAgencyCreateOrUpdate } from '../../../../../services/order-agency-update/order-agency-update.service';

const OrderAgencyEditContainer = () => {
  const { orderSelected: order, setOrderUpdated } = useOrderStore();
  const { orderAgencyCreateOrUpdate, orderUpdated, loading } =
    useOrderAgencyCreateOrUpdate();
  const { closeDrawer } = useDrawer();

  const handleSubmit = (data: IOrderUpdateSchema) => {
    orderAgencyCreateOrUpdate(data);
  };

  useEffect(() => {
    if (orderUpdated) {
      closeDrawer();
      setOrderUpdated(orderUpdated);
    }
  }, [orderUpdated]);

  return (
    <>
      {order ? (
        <>
          <Space align="baseline">
            <Title level={3}>Orden #{order?.orderNumber}</Title>
          </Space>

          <div style={{ marginBottom: '1.5rem' }}>
            <div>
              <Space>
                <div>
                  <b>Fecha de pedido:</b>
                </div>
                <div>{formatDate(order?.createdAt)}</div>
              </Space>
            </div>
            <div>
              <Space>
                <div>
                  <b>Vendedor:</b>
                </div>
                <div>{order.user?.fullname}</div>
              </Space>
            </div>
          </div>
        </>
      ) : (
        <Space align="baseline">
          <Title level={4}>Nueva orden</Title>
        </Space>
      )}

      <Spin spinning={loading}>
        <OrderAgencyEditForm initialValues={order} onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default OrderAgencyEditContainer;
