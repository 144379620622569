import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'

export const useOrderShopify = () => {
  const { authUser } = useAuthStore()

  if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER') {
    return true
  }

  return false
}

export const usePreOrderEdit = () => {
  const { roleUser } = useAuthStore()

  let userCompany = 0

  if (roleUser?.name === 'ADMIN_STORE' || roleUser?.name === 'SELLER') {
    userCompany = 2
  } else if (roleUser?.name === 'ADMIN' || roleUser?.name === 'STORE' || roleUser?.name === 'MASTER') {
    userCompany = 1
  } else if (roleUser?.name === 'SUPER_MASTER') {
    userCompany = 3
  }

  return { userCompany }
}

export const useCountryEdit = () => {
  const { authUser } = useAuthStore()
  let requiredByCountry = false
  const countryCode = authUser.user.company.countryCode
  
  if (countryCode === 'PER') {
    requiredByCountry = true
  }

  return { requiredByCountry }
}

export const useEcomPremiun = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()

  const flagPremium = companyGlobal ? companyGlobal.premium : authUser.user.company.premium

  return { flagPremium }

}

export const useUserRolStore = () => {
  const { authUser } = useAuthStore()

  const isStore = authUser.user.role.name === 'STORE'

  return { isStore }
}
