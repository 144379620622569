import React, { FC, PropsWithChildren } from 'react';
import { Drawer as DrawerAnt, DrawerProps } from 'antd';
import { useBreakpoint } from '../../hooks';

export const Drawer: FC<DrawerProps & PropsWithChildren> = (props) => {
  const breakpoint = useBreakpoint();

  const { children, ...drawerProps } = props;
  const isMobile = breakpoint === 'xs';

  return (
    <DrawerAnt
      destroyOnClose
      width={isMobile ? '100%' : drawerProps.width || 520}
      {...drawerProps}
    >
      {children}
    </DrawerAnt>
  );
};
