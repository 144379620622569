import { useEffect } from 'react';
import { useAxios } from '../../hooks';
import { TCountryPhone } from './country-phone.type';

export const useCountryPhone = () => {
  const { execute, response, loading } = useAxios<TCountryPhone[]>();

  useEffect(() => {
    execute({
      method: 'GET',
      url: '/country-phone/all',
    });
  }, []);

  return {
    loading,
    countryPhoneList: response || [],
  };
};
