import { useAuthStore } from '../auth/auth.service'

export const useProductEdit = () => {
  const { authUser } = useAuthStore()
  let companyUser = 0
  let isEditable = true
  const roleName = authUser.user.role.name

  if (roleName === 'ADMIN_STORE' || roleName === 'SELLER') {
    companyUser = 2
    isEditable = true
  } else if (roleName === 'ADMIN' || roleName === 'MASTER' || roleName === 'STORE'
    || roleName === 'SUPER_MASTER'
  ) {
    companyUser = 1
    isEditable = false
  }

  return { companyUser, isEditable }
}
