import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import { PageHeader } from '../../../../../components';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { IOrderFilter } from '../../../../../services/order-list/order.interface';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { DeliveryTable } from '../../components';
import { DeliveryFilterForm } from '../../forms';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import {
  getProvinceList,
  useConveyorStore,
} from '../../../../../services/location/location.service';
import { useOrderDispatchedList } from '../../../../../services/order-dispatched/order-dispatched.service';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import { useUserListTransport } from '../../../../../services/order-create/order.edit';

const { Option } = Select;

const OrdersAssignmentListContainer = () => {
  const { orders, loading, getOrders } = useOrderDispatchedList();
  const { warehouses, loadingWarehouses, getWarehouses } =
    useWarehouseByCountry();
  const { orderUpdated, orderDeliveryChecked, setOrderDeliveryChecked } =
    useOrderStore();
  const { guideAssignUpdated, setDispatchGuideSelected, dispatchGuideUpdated } =
    useDispatchGuideStore();
  const { authUser } = useAuthStore();
  const [filterValue, setFilterValue] = useState<IOrderFilter>();
  const { openDrawer } = useDrawer();
  const provinceList = getProvinceList();
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL');
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL');
  const [warehouseSelected, setWarehouseSelected] = useState<string>('ALL');
  const [defaultSend] = useState<string>('0');
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('');
  const [transpIdSelected, setTranspIdSelected] = useState<string>('');
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const { setConveyorSelected } = useConveyorStore();
  const { isComboxTransp } = useUserListTransport();

  const handleSubmit = (filter: IOrderFilter) => {
    setFilterValue(filter);
    getOrders({
      ...filter,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      conveyorId: transpIdSelected,
      warehouseName: warehouseSelected,
    });
  };

  useEffect(() => {
    if (transporters && transporters.length > 0) {
      setTranspIdSelected(`${transporters[0].id}`);
      setTranspSelected(`${transporters[0].name}`);

      setConveyorSelected({
        id: transporters[0].id,
        name: transporters[0].name,
        coverages: [],
        isConveyorPremium: false,
      });

      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${transporters[0].id}`,
      });
    }
  }, [transporters]);

  const handleGuideAssignChange = () => {
    openDrawer('ASSIGN_DISPATCH_EDIT', 'Asignar Guía');
  };

  const handleCreateGuideChange = () => {
    setDispatchGuideSelected(null);
    openDrawer('DISPATCH_GUIDE_EDIT', 'Crear guía');
  };

  const handleChangeProvince = (ubigeId: string) => {
    getOrders({
      ...filterValue,
      provinceCode: ubigeId,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: warehouseSelected,
      conveyorId: transpIdSelected,
    });
    setDefaultSelected(ubigeId);
  };

  const handleChangeWarehouse = (value: string) => {
    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: value,
      conveyorId: transpIdSelected,
    });
    setWarehouseSelected(value);
  };

  const handleChangeDispatch = (value: string) => {
    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: value,
      warehouseName: warehouseSelected,
      conveyorId: transpIdSelected,
    });
    setDispatchSelected(value);
  };

  useEffect(() => {
    if (orderUpdated || guideAssignUpdated || dispatchGuideUpdated) {
      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: transpIdSelected,
      });
    }
  }, [orderUpdated, guideAssignUpdated, dispatchGuideUpdated]);

  useEffect(() => {
    if (isComboxTransp === false) {
      getTransporters();
      setHiddenTransp(false);
    } else if (isComboxTransp === true) {
      setHiddenTransp(true);
      setTranspIdSelected(`${authUser.user.conveyor?.id}`);

      if (authUser.user.conveyor) {
        setConveyorSelected({
          id: authUser.user.conveyor?.id,
          name: '',
          coverages: [],
          isConveyorPremium: false,
        });
      }

      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${authUser.user.conveyor?.id}`,
      });
    }
  }, []);

  useEffect(() => {
    setOrderDeliveryChecked([]);
    getWarehouses();
  }, [orders]);

  const handleChangeTransporter = (id: string) => {
    const transp = transporters.find((t) => `${t.name}` === `${id}`);

    if (transp) {
      setConveyorSelected(transp);
      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
        conveyorId: `${transp.id}`,
      });

      setTranspIdSelected(`${transp.id}`);
    }

    setTranspSelected(id);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {hiddenTransp ? (
              <></>
            ) : (
              <>
                <h5>Transportadora</h5>
                <Select
                  onChange={handleChangeTransporter}
                  showSearch
                  optionFilterProp="key"
                  loading={loadingTransporters}
                  value={transpSelected}
                  style={{ minWidth: '120px' }}
                  filterOption={(input, option) =>
                    (option?.key as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {transporters?.map((key) => (
                    <Option key={key.name} value={key.name}>
                      {key.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}

            <DeliveryFilterForm onChange={handleSubmit} />

            <Button
              type="primary"
              onClick={handleCreateGuideChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Crear guía
            </Button>

            <Button
              type="primary"
              onClick={handleGuideAssignChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Asignar guía
            </Button>

            {authUser.user.warehouse ? (
              <></>
            ) : (
              <>
                <h5>Almacén</h5>

                <Select
                  placeholder="Almacén"
                  showSearch
                  loading={loadingWarehouses}
                  optionFilterProp="label"
                  style={{ minWidth: '150px' }}
                  onChange={handleChangeWarehouse}
                  value={warehouseSelected}
                  filterOption={(input, option) =>
                    (option?.label as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option key="ALL" value="ALL" label={'TODOS'}>
                    TODOS
                  </Option>
                  {warehouses.map((key) => (
                    <Option key={key.id} value={key.name} label={key.name}>
                      {key.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            <h5>Estado Despacho</h5>
            <Select
              placeholder="Despacho"
              showSearch
              optionFilterProp="label"
              style={{ minWidth: '130px' }}
              onChange={handleChangeDispatch}
              value={dispatchSelected}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option key="ALL" value="ALL" label={'TODOS'}>
                TODOS
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                value={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.TO_PREPARE
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.TO_PREPARE
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                value={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.PREPARED
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.PREPARED
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                value={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT
                  ].label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[
                    ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT
                  ].label
                }
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PICKED}
                value={ORDER_DISPATCH_STATUS_LIST.PICKED}
                label={
                  ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED]
                    .label
                }
              >
                {
                  ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED]
                    .label
                }
              </Option>
            </Select>
            <h5>Provincia</h5>
            <Select
              placeholder="Provincia"
              showSearch
              optionFilterProp="label"
              style={{ minWidth: '130px' }}
              onChange={handleChangeProvince}
              value={defaultSelected}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option key="ALL" value="ALL" label={'TODOS'}>
                TODOS
              </Option>
              {provinceList.map((key) => (
                <Option
                  key={key.id_ubigeo}
                  value={key.id_ubigeo}
                  label={key.nombre_ubigeo}
                >
                  {key.nombre_ubigeo}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </PageHeader>

      {/*orders?.length ? (
        <DeliveryResumen orders={orders} />
      ) : null*/}

      <DeliveryTable data={orders} loading={loading} />
    </>
  );
};

export default OrdersAssignmentListContainer;
