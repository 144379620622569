import { useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { useAuthStore } from '../services/auth/auth.service';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes/routes.param';
import { useLocalStorage } from './use-local-storage';
import { IAuthResponse } from '../services/auth/auth.interface';
import { infoDevice } from '../utils/date';

axios.defaults.baseURL = 'https://aliclik-store-b4a58dbc118c.herokuapp.com'
//axios.defaults.baseURL = 'https://aliclikappdev-api.herokuapp.com';
//axios.defaults.baseURL = 'http://192.168.1.61:2500'
// axios.defaults.baseURL = 'http://localhost:3000';

interface IAxiosParams {
  method: Method | string;
  url: string;
  data?: unknown;
  params?: unknown;
  token?: string;
  baseUrl?: string;
  isSuccess?: boolean;
}

export const useAxios = <T>() => {
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { authUser } = useAuthStore();
  const navigate = useNavigate();
  const { clear } = useLocalStorage<IAuthResponse>('ALP_AuthToken');

  const execute = async (axiosParams: IAxiosParams) => {
    const { browser, os } = infoDevice();

    const axiosRequestConfig: AxiosRequestConfig = {
      method: axiosParams.method,
      url: axiosParams.url,
      headers: {
        Authorization: `Bearer ${axiosParams.token === 'undefined' ? axiosParams.token : authUser?.token}`,
        'X-Platform': 'web',
        'X-Device': `${browser}/${os}`,
      },
      params: axiosParams.params,
      data: axiosParams.data,
    };

    if (axiosParams.baseUrl) {
      axiosRequestConfig.baseURL = axiosParams.baseUrl;
    }

    setLoading(true);
    setError(null);

    return axios
      .request(axiosRequestConfig)
      .then((response: AxiosResponse) => {
        if (['POST', 'PATCH'].includes(axiosParams.method)) {
          if (axiosParams.isSuccess) {
            //none
          } else {
            message.success(response.data?.message || 'Operación exitosa');
          }
        }
        setResponse(response.data);

        return response.data;
      })
      .catch((error) => {
        setError(error);
        message.error(error.response?.data?.message || 'Ha ocurrido un error inesperado 😟');

        const messageError = error.response?.data?.message || '';

        if (messageError === 'Su sesión ha terminado') {
          clear();
          navigate(ROUTES.SIGN_IN.PATH, { replace: true });
        }

        return Promise.reject(error);
      })
      .finally(() => setLoading(false));
  };

  return {
    execute,
    response,
    error,
    loading,
  };
};
