import React, { useEffect } from 'react'
import { Form, Input, Button, message, Space, Divider, Select } from 'antd'
import { ITransporterCreateSchema } from '../../../../../services/transporter-create/transporter-create.schema'

const { Option } = Select

interface TransporterEditFormProps {
  onSubmit: (data: ITransporterCreateSchema) => void
  isEditMode: boolean
  onCancel: () => void
  initialValues?: any // Nuevo: para pasar los valores iniciales de la transportadora
}

const TransporterEditForm: React.FC<TransporterEditFormProps> = ({ onSubmit, isEditMode, onCancel, initialValues }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (isEditMode && initialValues) {
      form.setFieldsValue(initialValues)
    } else {
      form.resetFields()
    }
  }, [isEditMode, form, initialValues])

  const handleFinish = (values: ITransporterCreateSchema) => {
    const urlPattern = /^(https?:\/\/)/

    if (!urlPattern.test(values.urlImage)) {
      message.error('Por favor, ingrese una URL válida que comience con http:// o https://')

      return
    }
    onSubmit(values)
    form.resetFields()
  }

  return (
    <Form form={form} onFinish={handleFinish} initialValues={{ urlImage: '', isActive: true }}>
      {!isEditMode && (
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'Por favor ingrese el nombre de la transportadora' }]}
        >
          <Input placeholder="Nombre de la transportadora" />
        </Form.Item>
      )}

      <Form.Item
        label="URL de Imagen"
        name="urlImage"
        rules={[
          { required: true, message: 'Por favor ingrese la URL de la imagen' },
          { pattern: /^(https?:\/\/)/, message: 'La URL debe comenzar con http:// o https://' },
        ]}
      >
        <Input placeholder="https://ejemplo.com/imagen.jpg" />
      </Form.Item>

      <Form.Item
        label="Estado"
        name="isActive"
        rules={[{ required: true, message: 'Por favor seleccione el estado' }]}
      >
        <Select placeholder="Seleccione el estado">
          <Option value={true}>Activo</Option>
          <Option value={false}>Inactivo</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button onClick={onCancel}>Cancelar</Button>
          <Divider type="vertical" />
          <Button type="primary" htmlType="submit">
            {isEditMode ? 'Actualizar' : 'Agregar'} Transportadora
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default TransporterEditForm
